<div class="param-main-block">
    <h2 tabindex="0" class="param-page-title">{{ rsc.pageTitle }}</h2>

    <div [ngClass]="{ 'param-contrats-border': areContratsLoaded }" tabindex="0">
        <app-contrats (onSelectContrat)="onSelectedContrat($event)"></app-contrats>
    </div>

    @if (areContratsLoaded) {
    <div class="m-t-30" tabindex="0">
        <label id="param-demat-radio-group-label">{{ rsc.titleDematChoice }}</label>
        <mat-radio-group aria-labelledby="param-demat-radio-group-label" class="param-demat-radio-group"
            [(ngModel)]="dematSelected">
            @for ( dematChoice of dematChoices ; track dematChoice) {
            <mat-radio-button class="param-demat-radio-button" [value]="dematChoice.id">
                {{dematChoice.name}}
            </mat-radio-button>
            }

        </mat-radio-group>
    </div>
    }


    <div fxLayout="column">
        @if (isStatutEnCours === true) {
        <div fxLayout="row" fxLayoutAlign="start" class="param-message-user">
            <i-feather name="alert-triangle" class="m-r-20">
            </i-feather>
            <span>{{messageUser}}</span>
        </div>
        }

        <button [disabled]="!areContratsLoaded || isLoggedAs" [disableRipple]="true" mat-flat-button
            class="btn-block btn-display btn-carbon" (click)="saveParameters()">
            <span class="white font-small">{{ rsc.saveButton }}</span>
        </button>
    </div>
</div>