import { HttpClient, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { Observable, of, Subscription } from 'rxjs';
import { Inscription } from '../../shared/models/inscription';
import { LocataireService } from '../../shared/services/locataire.service';
import { logoutRequest, tokenRequest } from '../b2c-config';
import { Constants } from './constants.service';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { UserHabilitations } from '../../shared/models/user-habilitations';
import { MixinService } from './mixin.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    accountId: string;
    userEmail: string;
    routeSubscription: Subscription;
    browserRefreshed: boolean;
    adminConnected: boolean;
    token: string;

    // store the URL so we can redirect after logging in
    public redirectUrl = '/';

    /**
     * Build an instance of AuthService.
     * @param {HttpClient} http
     * @param {Router} router
     * @param {Constants} constants
     * @param {AppConfig} appConfig
     * @param {StorageService} storageService
     * @param {LocataireService} userService
     * @param {MsalGuardConfiguration} msalGuardConfig
     * @param {MsalService} msalService
     * @memberof AuthService
     */
    constructor(
        private http: HttpClient,
        public router: Router,
        private constants: Constants,
        private storageService: StorageService,
        private userService: LocataireService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private mixinService: MixinService
    ) {
        // Permet de vérifier si la page vient d'être refresh
        this.routeSubscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Récupère l'url vers laquelle rediriger après le refresh de la page
                this.browserRefreshed = !router.navigated && event.url.indexOf('authentication') === -1;
                if (this.browserRefreshed) {
                    this.redirectUrl = event.url;
                }
            }
        });
    }

    /**
     * Appelle la fenêtre d'authentification B2C, soit via popup, soit via redirection (la partie popup est nécessaire
     * pour certains navigateurs comme IE)
     *
     * @param {RedirectRequest} userFlowRequest?
     * @memberof AuthService
     */
    login(userFlowRequest?: RedirectRequest): void {
        // Login avec redirection, dans ce cas voir msalService.handleRedirectObservable dans app.component.ts
        // pour la gestion du retour success/error
        if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
        } else {
            this.msalService.loginRedirect(userFlowRequest);
        }
    }

    /**
     * Récupère le token
     *
     * @returns {string}
     * @memberof AuthService
     */
    getToken(): string {
        return this.token;
    }

    /**
     * Connecte un admin
     *
     * @param {AuthenticationResult} authResult : info de connexion provenant du login MSAL
     * @memberof AuthService
     */
    connectAdmin(authResult: AuthenticationResult): void {
        const selectedAccount = authResult.account;
        if (selectedAccount) {
            this.adminConnected = true;
            this.storageService.storeInSessionStorage(this.constants.APP_USER_EMAIL, selectedAccount.username);
            // Modifie l'url de redirection uniquement si la page n'a pas été refresh
            if (!this.browserRefreshed) {
                this.redirectUrl = '/admin';
            }
        }
        this.connectUser(authResult);
    }

    /**
     * Connecte un locataire
     *
     * @param {AuthenticationResult} authResult : info de connexion provenant du login MSAL
     * @memberof AuthService
     */
    connectLocataire(authResult: AuthenticationResult): void {
        const selectedAccount = authResult.account;
        if (selectedAccount) {
            this.adminConnected = false;
            // sauvegarde l'email du token
            this.storageService.storeInSessionStorage(this.constants.APP_USER_EMAIL, selectedAccount.username);
            this.userService.setLocataire();
            // Modifie l'url de redirection uniquement si la page n'a pas été refresh
            if (!this.browserRefreshed) {
                this.redirectUrl = '/locataire';
            }
        }
        this.connectUser(authResult);
    }

    /**
     * Connecte l'utilisateur, admin ou locataire
     *
     * @param {AuthenticationResult} authResult : info de connexion provenant du login MSAL
     * @returns {boolean}
     * @memberof AuthService
     */
    connectUser(authResult: AuthenticationResult): boolean {
        this.storageService.storeInSessionStorage(this.constants.APP_AUTH, authResult);
        const selectedAccount = authResult.account;
        let loggedIn;
        if (selectedAccount) {
            this.msalService.instance.setActiveAccount(selectedAccount);
            this.accountId = selectedAccount.homeAccountId;
            this.userEmail = selectedAccount.username;
            loggedIn = true;
            this.router.navigate([this.redirectUrl]);
        } else {
            loggedIn = false;
            this.storageService.clearSessionStorage();
        }
        this.storageService.storeInSessionStorage(this.constants.LOGGED_IN, loggedIn);
        return loggedIn;
    }

    /**
     * Vérifie si l'utilisateur est un admin
     *
     * @returns boolean
     * @memberof AuthService
     */
    isAdmin(): boolean {
        if (!this.adminConnected) {
            const authInfos = this.storageService.getFromSessionStorage(this.constants.APP_AUTH);
            this.adminConnected = authInfos?.idTokenClaims?.idp;
        }
        return this.adminConnected;
    }

    /**
     * Appelle la récupération de token B2C (acquireTokenSilent = non visible par l'utilisateur)
     * Dans le cas où l'utilisateur a refresh la page, récupère les infos précédemment stockées
     *
     * @returns {Observable<AuthenticationResult>}
     * @memberof AuthService
     */
    getTokenAsync(): Observable<AuthenticationResult> {
        if (this.isLoggedIn()) {
            if (this.isAdmin()) {
                return this.msalService.acquireTokenSilent(tokenRequest.admin);
            } else {
                return this.msalService.acquireTokenSilent(tokenRequest.locataire);
            }
        } else if (this.browserRefreshed) {
            return of(this.storageService.getFromSessionStorage(this.constants.APP_AUTH));
        }
    }

    /**
     * Vérifie si l'utilisateur est connecté
     *
     * @returns {boolean}
     * @memberof AuthService
     */
    isLoggedIn(): boolean {
        return this.storageService.getFromSessionStorage(this.constants.LOGGED_IN)
            && (this.storageService.getFromSessionStorage(this.constants.APP_USER_EMAIL) !== null
                || this.isAdmin());
    }

    /**
     * Définit la route en fonction du type de connexion
     * 
     * @returns {string}
     * @memberof AuthService
     */
    getBaseUrl(): string {
        return this.isAdmin() && !this.isLoggedAs() ? '/admin' : '/locataire';
    }

    /**
     * Récupére le type d'utilisateur en fonction du type de connexion
     * 
     * @returns {string}
     * @memberof AuthService
     */
    getUserType(): string {
        return this.isAdmin() && !this.isLoggedAs() ? 'admin' : 'locataire';
    }

    /**
     * Ajout du token et des informations nécessaires au header, telle que l'APIM subscription key, pour les appels APIs
     *
     * @param {HttpRequest<any>} request
     * @param {string} token
     * @returns {HttpRequest<any>}
     * @memberof AuthService
     */
    addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        this.token = token;
        return request.clone({
            setHeaders: {
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Authorization': `Bearer ${token}`,
                'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
            },
            setParams: {
                'version': this.getVersion(request)
            }
        });
    }

    /**
     *
     * @param {HttpRequest<any>} request
     * @returns {HttpRequest<any>}
     * @memberof AuthService
     */
    addDefaultHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                'Accept': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Ocp-Apim-Subscription-Key': environment.subscriptionKey,
            },
            setParams: {
                'version': this.getVersion(request)
            }
        });
    }

    /**
     * Récupère la version du fichier de config en fonction du type d'api (public ou protected)
     *
     * @param {HttpRequest<any>} request
     * @returns {string}
     * @memberof AuthService
     */
    getVersion(request: HttpRequest<any>): string {
        if (this.requestOnAPIM(request) && !this.requestOnPublicAPIM(request)) {
            return environment.version.protected;
        } else {
            return environment.version.public;
        }
    }

    /**
     * Vérifie s'il est nécessaire d'ajouter les informations de connexion (token + APIM key) à une requête http
     * C'est le cas lorsque l'utilisateur est connecté et exécute une requête http vers l'API ref ou API Viveris
     *
     * @param {HttpRequest<any>} request
     * @returns {boolean}
     * @memberof AuthService
     */
    isAuthorizationHeaderNeeded(request: HttpRequest<any>): boolean {
        // Attention l'utilisateur peut être considéré non connecté dans le cas où la page vient d'être rafraichie
        // Il faut donc traiter ce cas séparément
        if (!this.isLoggedIn() && !this.browserRefreshed) {
            return false;
        } else {
            return this.requestOnAPIM(request) && !this.requestOnPublicAPIM(request);
        }
    }

    /**
     * Vérifie si l'appel http se fait vers une API de l'APIM (public ou protected)
     * 
     *
     * @param {HttpRequest<any>} request
     * @returns {boolean}
     * @memberof AuthService
     */
    requestOnAPIM(request: HttpRequest<any>): boolean {
        return request.url.indexOf(environment?.apiUrl?.protected) !== -1
            || request.url.indexOf(environment?.apiUrlViv?.protected) !== -1;
    }

    /**
     * Vérifie si l'appel http se fait vers une API public de l'APIM
     *
     * @param {HttpRequest<any>} request
     * @returns {boolean}
     * @memberof AuthService
     */
    requestOnPublicAPIM(request: HttpRequest<any>): boolean {
        return request.url.indexOf(environment?.apiUrl?.public) !== -1
            || request.url.indexOf(environment?.apiUrlViv?.public) !== -1;
    }

    /**
     * Appelle la déconnexion MSAL
     * Cette fonction doit retourner un observable mais cela ne fonctionne pas à cause de la redirection
     * La déconnexion est donc faite dans msalService.handleRedirectObservable
     *
     * @memberof AuthService
     */
    logout(): void {
        const authority = this.isAdmin() ? logoutRequest.admin : logoutRequest.locataire;
        this.msalService.logout(authority).subscribe(
            () => {
                this.adminConnected = false;
                this.storageService.storeInSessionStorage(this.constants.LOGGED_IN, false);
            }
        );
    }

    /**
     * Appel api pour vérifier l'existance du compte
     *
     * @param {Inscription} inscription
     * @returns {Observable<any>}
     * @memberof AuthService
     */
    checkInscription(inscription: Inscription): Observable<any> {
        return this.http.post(`${environment.apiUrl?.public}/VerificationInscription`, inscription);
    }

    /**
     * Vérifie si l'admin est connecté en tant que locataire
     * 
     * @returns {boolean}
     * @memberof AuthService
     */
    isLoggedAs(): boolean {
        return this.storageService.getFromSessionStorage(this.constants.LOGGED_AS_LOCATAIRE);
    }

    /**
     * Lister les droits des utilisateurs
     * 
     * @param {*} body
     * @returns {Observable<any>}
     * @memberof AuthService
     */
    listerUtilisateurs(body: any): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/ListerDroitsAdmin`, body);
    }

    /**
     * Lister les habilitations de l'utilisateur connecté
     * @returns {Observable<any>}
     * 
     * @memberof AuthService
     */
    getUserHabilitations(): Observable<any> {
        return this.http.get(`${environment.apiUrl?.protected}/params/profil_menu`);
    }

    /**
     * Lister les habilitations de l'utilisateur en connexion en tant que
     * 
     * @param {string }p_NumeroTiers 
     * @returns Observable<UserHabilitations>
     * 
     * @memberof AuthService
     */
    getUserHabilitationsEnTantQue(p_NumeroTiers: string): Observable<UserHabilitations> {
        return this.http.get(`${environment.apiUrl?.protected}/params/profil_menu/${p_NumeroTiers}`);
    }

    /**
     * Vérifie si l'utilisateur est autorisé à accéder à une ressource donnée
     * 
     * @param {string}ressourceName Le nom de la ressource
     * @param {string} accessType Le type d'accès nécessaire (read, update, create, etc)
     * @returns {boolean} True si l'utilisateur a accès à la ressource, false sinon
     * 
     * @memberof AuthService
     */
    isAuthorized(ressourceName: string, accessType?: string): boolean {
        const userHabilitations = this.mixinService.getCurrentContratHabilitations();
        if (!userHabilitations) {
            return false;
        }
        return (!accessType && userHabilitations[ressourceName] != null) || userHabilitations[ressourceName]?.include(accessType);
    }
}
