import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { AppConfig } from '../../../../core/services/app-config.service';
import { Constants } from '../../../../core/services/constants.service';
import { ErrorService } from '../../../../core/services/error.service';
import { MobileService } from '../../../../core/services/mobile.service';
import { StorageService } from '../../../../core/services/storage.service';
import { Contrat } from '../../../../shared/models/contrat';
import { Locataire } from '../../../../shared/models/locataire';
import { SharedContrats } from '../../../../shared/models/shared-contrats';
import { ParametresService } from '../services/parametres.service';

@Component({
    selector: 'app-parametres',
    templateUrl: './parametres.component.html',
    styleUrls: ['./parametres.component.scss']
})
export class ParametresComponent implements OnInit {
    isMobile: MediaQueryList;
    rsc: any;
    dematChoices: { id: number, name: string }[];
    dematSelected: number;
    areContratsLoaded: boolean = false;
    dematPayload: { numeroCA: string, codeOrganisme: number, numeroLocataire: string, isDemat: boolean };
    isStatutEnCours: boolean;
    messageUser: string;

    isLoggedAs: boolean;

    /**
     * Build an instance of ParametresComponent.
     * @param {AppConfig} appConfig
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {MobileService} mobileService
     * @param {ParametresService} parametresService
     * @param {ErrorService} errorService
     * @param {StorageService} storageService
     * @param {ToastrService} notificationService
     * @param {Constants} constants
     * @param {MatDialogRef<ParametresComponent>} dialogRef
     */
    constructor(
        private appConfig: AppConfig,
        private changeDetectorRef: ChangeDetectorRef,
        private mobileService: MobileService,
        private parametresService: ParametresService,
        private errorService: ErrorService,
        private storageService: StorageService,
        private notificationService: ToastrService,
        private constants: Constants,
        public dialogRef: MatDialogRef<ParametresComponent>,
    ) {
        this.isMobile = this.mobileService.mobileQueryListener(this.changeDetectorRef, '959');
    }

    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.pages.parametres;
        this.dematChoices = [
            { 'id': 0, 'name': this.rsc.dematLetter },
            { 'id': 1, 'name': this.rsc.dematEmail }
        ];
        this.dematPayload = { numeroCA: '', codeOrganisme: 0, numeroLocataire: '', isDemat: false };

        this.isLoggedAs = this.storageService.getFromSessionStorage(this.constants.LOGGED_AS_LOCATAIRE);
    }

    /**
     * Met à jour les données à chaque nouvelle sélection du contrat
     *
     * @memberof ParametresComponent
     * @param sharedContrats
     */
    onSelectedContrat(sharedContrats: SharedContrats): void {
        const contract = sharedContrats.selectedContrats;
        this.setDefaultPropertiesDemat();

        if (contract && contract.length === 1) {
            this.dematSelected = this.dematChoices[contract[0].ind_Demat].id;
            this.dematPayload.numeroCA = contract[0].numero_CA;
            this.dematPayload.codeOrganisme = contract[0].id_Organisme;
            setTimeout(() => {
                this.areContratsLoaded = true;
            });

            this.checkPrelevement(contract);
        }
    }

    saveParameters(): void {
        const user = this.storageService.getFromSessionStorage(this.constants.APP_USER) as Locataire;
        this.dematPayload.numeroLocataire = user.numero_Tiers;
        this.dematPayload.isDemat = !!this.dematSelected;
        this.parametresService.postDemat(this.dematPayload).subscribe(
            () => {
                this.notificationService.success(this.rsc.successNotification);
                this.dialogRef.close(true);
            },
            error => {
                return throwError(this.errorService.getErrorMessage(error));
            });
    }

    /**
     * Vérifie si un locataire a un prelevement actif
     * 
     * @param {contrat[]} contrat 
     * 
     * @memberof ParametresComponent
     */
    checkPrelevement(contrat: Contrat[]): void {
        if (contrat && contrat.length > 1) {
            this.setIsStatutEnCours(contrat);
            if (this.isStatutEnCours) {
                this.messageUser = this.rsc.messageStatut;
            }
        }
    }

    /**
     * Vérifie si le statut demat est en cours
     * 
     * @param {Contrat} contrat 
     * @returns {boolean} isStatutEnCours
     * 
     * @memberof ParametresComponent
     */
    setIsStatutEnCours(contrat: Contrat[]): void {
        this.isStatutEnCours = contrat[0].ind_Demat_A_Jour === 0;
    }

    /**
     * Réinitialise par défaut les propriétés
     * 
     * @memberof ParametresComponent
     */
    setDefaultPropertiesDemat(): void {
        this.isStatutEnCours = false;
        this.rsc.messageUser = '';
    }

}
