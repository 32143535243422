export const environment = {
  production: true,
  name: "production",
  redirectUri: "https://espaceclient.icfhabitat.fr/",
  ICFauthorityDomain: "espacelocataireicfprod.b2clogin.com",
  ICFB2C: "https://espacelocataireicfprod.b2clogin.com/espacelocataireicfprod.onmicrosoft.com/",
  ICFB2CScope: "https://espacelocataireicfprod.onmicrosoft.com/e275fac3-e31c-45f5-8f67-2efd1cfba1e1/Connexion",
  ICFSignIn: "B2C_1A_SignIn",
  ICFSignUp: "B2C_1A_SignUp",
  ICFSignInUpADD: "B2C_1A_SignIn_SignUp_AAD",
  ClientIDB2C: "e275fac3-e31c-45f5-8f67-2efd1cfba1e1",
  version: {
    public: "v2",
    protected: "v2"
  },
  apiUrl: {
    public: "https://api-icf-int-production.azure-api.net/Connexion/api/public",
    protected: "https://api-icf-int-production.azure-api.net/EspaceLoc/api"
  },
  apiUrlViv: {
    public: "https://api-icf-int-production.azure-api.net/Connexion/api",
    protected: "https://api-icf-int-production.azure-api.net/EspaceLoc/api"
  },
  subscriptionKey: "159e8b85e8d14a4aa039031b99aa4c1b",
  vapidPublicKey: "BFN8CjjDDcpF-2OXqvLWZHZVJmrOGDCivS3TqXhwXAmUt_CiecFBBS85n6-9krXW6ZRIF5-14ds2EVKjhhNG4Ww",
  urlGestionAbo: "https://app-front-gestiondesabonnements-prod.azurewebsites.net"
};
