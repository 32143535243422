import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent
} from '@angular/common/http';

// RxJS
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

// Services
import { Spinner } from './../services/spinner.service';

/**
 * Application spinner Interceptor
 *
 * @export
 * @class SpinnerInterceptor
 * @implements {SpinnerInterceptor}
 */
@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    private requestCount = 0;

    constructor(private spinner: Spinner) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.requestCount === 0) {
            // La première requête est lancée, affiche le spinner
            this.spinner.show();
        }
        this.requestCount++;

        return next.handle(req)
            .pipe(finalize(() => {
                this.requestCount--;
                if (this.requestCount === 0) {
                    // Toutes les requêtes sont terminées, cache le spinner
                    this.spinner.hide();
                }
            }));
    }
}
