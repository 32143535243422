import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { IMenu, IPageTitle } from '../../shared/models/menu';
import { Constants } from './constants.service';
import { StorageService } from './storage.service';

/**
 * Application mixin service
 *
 * @export
 * @class MixinService
 */
@Injectable({
    providedIn: 'root',
})
export class MixinService {
    public fr: any;
    public rsc: any;
    public notifOpts: any;
    public emptyMessage: string;
    public maskDate: any[];
    public maskPhone: any[];
    public maskEmail: any[];
    public maskZipCode: any[];
    public navigationMenu: IMenu[];

    /**
     * Build an instance of MixinService.
     * 
     * @param {HttpClient} http
     * @param {StorageService} storageService
     * @param {Constants} constants
     * @param {Router} router
     * @param {DecimalPipe} decimalePipe
     * 
     * @memberof MixinService
     */
    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private constants: Constants,
        private router: Router,
        private decimalePipe: DecimalPipe
    ) {
        this.notifOpts = {
            position: ['bottom', 'right'],
            clickToClose: true,
            maxLength: 100
        };
    }

    /**
     * Clone Object
     *
     * @param {*} obj
     * @returns {*}
     * @memberof MixinService
     */
    clone(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    /**
     * Find object by label
     *
     * @param {*} obj
     * @param {string} label
     * @returns {*}
     * @memberof MixinService
     */
    findObjectByLabel(obj: any, label: string): any {
        if (obj[label] === label) {
            return obj;
        }
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                const foundLabel = this.findObjectByLabel(obj[i], label);
                if (foundLabel) {
                    return foundLabel;
                }
            }
        }
        return null;
    }

    /**
     * Get current timestamp
     *
     * @param {number} addDays
     * @returns {Date}
     * @memberof MixinService
     */
    getCurrentDate(addDays?: number): Date {
        let currentDate = new Date();
        if (addDays !== null && addDays !== undefined) {
            // this computation is a way to get the time without the time since midnight
            const time: number = currentDate.getTime()
                - currentDate.getHours() * 3600 * 1000
                - currentDate.getMinutes() * 60 * 1000
                - currentDate.getSeconds() * 1000
                - currentDate.getMilliseconds();
            currentDate = new Date(time + (addDays * (24 * 3600 * 1000)));
        }
        return currentDate;
    }

    /**
     * Get current TimeStamp
     *
     * @returns {number}
     * @memberof MixinService
     */
    getCurrentTimeStamp(): number {
        return new Date().getTime();
    }

    /**
     * Get today date
     *
     * @param {number} [addDays]
     * @returns {string}
     * @memberof MixinService
     */
    getTodayDate(addDays?: number): string {
        let currentDate = this.getCurrentDate(addDays),
            dd = currentDate.getDate().toString(),
            mm = (currentDate.getMonth() + 1).toString(), // January is 0!
            yyyy = currentDate.getFullYear().toString(),
            today;

        if (Number.parseInt(dd) < 10) {
            dd = '0' + dd;
        }

        if (Number.parseInt(mm) < 10) {
            mm = '0' + mm;
        }

        return dd + '/' + mm + '/' + yyyy;
    }

    /**
     * Date to string
     *
     * @param {Date} date
     * @returns {string}
     * @memberof MixinService
     */
    dateToString(date: Date): string {
        let dd = date.getDate().toString(),
            mm = (date.getMonth() + 1).toString(), // January is 0!
            yyyy = date.getFullYear().toString(),
            today;

        if (Number.parseInt(dd) < 10) {
            dd = '0' + dd;
        }

        if (Number.parseInt(mm) < 10) {
            mm = '0' + mm;
        }

        return dd + '/' + mm + '/' + yyyy;
    }

    /**
     * Get date comparer for sorting
     *
     * @param {string} dateOne
     * @param {string} dateTwo
     * @returns {number}
     *
     * @memberof MixinService
     */
    getDateComparer(dateOne: string, dateTwo: string): number {
        const a = (dateOne === null ? '' : dateOne);
        const b = (dateTwo === null ? '' : dateTwo);
        dateOne = a.split('/').reverse().join('');
        dateTwo = b.split('/').reverse().join('');
        return (dateOne > dateTwo ? 1 : dateOne < dateTwo ? -1 : 0);
    }

    /**
     * Verify if the date is valid
     *
     * @param {string} date
     * @returns
     * @memberof MixinService
     */
    isValidDate(date: string) {
        // tslint:disable-next-line:max-line-length
        const DATE_REGEXP = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        return DATE_REGEXP.test(date);
    }

    /**
     * Remove special chars from given string
     *
     * @param {string} str
     * @returns {string}
     *
     * @memberof MixinService
     */
    removeSpecials(str: string): string {
        return str.replace(/[&\/\\#,+()$~%.'':*?<>{}]/g, '');
    }

    /**
     * Detect IE browser
     *
     * @returns {boolean}
     *
     * @memberof MixinService
     */
    isIE(): boolean {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        const trident = ua.indexOf('Trident/');
        const edge = ua.indexOf('Edge/');

        if (msie > 0 || trident > 0 || edge > 0) {
            return true;
        }

        // other browsers
        return false;
    }

    /**
     * Instanciation des menus de l'application
     * 
     * @param {*} navSidebarList
     * @param {string} userType
     * @memberof MixinService
     */
    setNavigationMenus(navSidebarList: any, userType: string): void {
        const setMenu = function (jsonMenu: JSON, navigationMenu: IMenu[]) {
            Object.entries(jsonMenu).forEach(
                ([key, value]) => {
                    if (value['children'] !== undefined) {
                        setMenu(value['children'], navigationMenu);
                    } else {
                        navigationMenu.push(<IMenu>value);
                    }
                }
            );
        };

        const navBarMenu = navSidebarList[userType].menuItems;
        const navButtons = navSidebarList[userType].buttons;
        this.navigationMenu = [];
        setMenu(navBarMenu, this.navigationMenu);
        setMenu(navButtons, this.navigationMenu);
    }

    /**
     * Retourne le nom de la route en paramètre
     * @param {string} routeUrl url de la route
     * @param {any} navSidebarList
     * @param {string} userType
     * @returns {IPageTitle | null}
     * @memberof MixinService
     */
    getPageTitle(routeUrl: string, navSidebarList: any, userType: string): IPageTitle | null {
        if (!this.navigationMenu || this.navigationMenu.length === 0) {
            this.setNavigationMenus(navSidebarList, userType);
        }

        if (this.navigationMenu && this.navigationMenu.length > 0) {
            const menu: IMenu = this.navigationMenu.find(item => item.url === routeUrl);
            if (menu && menu.name) {
                return {
                    title: menu.name,
                    icon: menu.icon,
                    iconType: menu.iconType
                };
            } else {
                return null;
            }
        }
    }

    /**
     * Modifie le format du numéro de téléphone en 
     * ajoutant un espace tous les deux chiffres
     * 
     * @param {string} chaineNumerique
     * @returns {string}
     * @memberof MixinService
     */
    frenchPhoneTransform(chaineNumerique: string): string {
        var chaine = "";
        chaineNumerique.split('').forEach((char: string, index: number) => {
            chaine = chaine.concat(char);
            if (index % 2 !== 0 && index !== 9) {
                chaine = chaine.concat(" ");
            }
        });

        return chaine;
    }

    /**
     * Reload actual page
     *
     * @memberof MixinService
     */
    refresh(): void {
        window.location.reload();
    }

    /**
     * Nettoyage d'une liste
     * 
     * @param {string[]} list
     * @returns {string[]}
     * 
     * @param {string[]} list
     * @returns {string[]}
     * @memberof MixinService
     */
    cleanList(list: string[]): string[] {
        return list.filter(element => element);
    }

    /**
     * Load file
     *
     * @param {string} path
     * @returns {Promise<any>}
     * @memberof MixinService
     */
    loadFile(path: string): Promise<any> {
        return new Promise((resolve) => {
            this.http.get(path)
                .pipe(
                    map((res: any) => res),
                    catchError((error) => {
                        throw error;
                    })
                ).subscribe((res_data) => resolve(res_data));
        });
    }

    /**
     * Vérifie dans le session storage si un locataire contient uniquement des contrats partis
     * 
     * @memberof MixinService
     */
    checkContratsPartisUniquement(): void {
        const contratsPartisUniquement = this.storageService.getFromSessionStorage(this.constants.CONTRATS_PARTIS_ONLY);

        if (contratsPartisUniquement === true) {
            this.router.navigate([this.constants.URL_AUCUN_CONTRAT]);
        }
    }

    /**
     * Récupére le code organisme du locataire directement
     * dans le session storage
     * 
     * @returns {String}
     * 
     * @memberof MixinService
     */
    getCodeOrganismeLocataire(): string {
        return this.storageService.getFromSessionStorage(this.constants.APP_USER_ORGANISME);
    }
    
    /**
     * Récupère les habilitations du contrat actuellement sélectionné
     * @returns {any} - 
     */
    getCurrentContratHabilitations(): any {
        let userHabilitations = this.storageService.getFromSessionStorage(this.constants.HABILITATIONS);
        let selectedContrat = this.storageService.getFromSessionStorage(this.constants.NUMERO_CONTRAT);
        return selectedContrat ? userHabilitations[selectedContrat] : null;
    }

    /**
     * Supprime les espaces pour
     * une châine de caractères
     * 
     * @param {String} stringToClean 
     * 
     * @returns string
     */
    removeSpacesInString(stringToClean: string): string {
        return stringToClean.replace(/\s+/g, '');
    }

    /**
     * Condition qui vérifie si un prélèvement est actif ou non
     * 
     * @param {number} prelevementActif 
     * @returns {boolean}
     * 
     * @memberof MixinService
     */
    checkIsPrelevementActif(prelevementActif: number): boolean {
        return prelevementActif === 1;
    }


    /**
     * Converti la valeur de l'index avec des décimales ou sans décimales
     * 
     * @param {Number} index
     * @returns {string | number}
     * @memberof MixinService
     * 
     */
    transformIndexDecimal(index: number): string | number {
        return Number.isInteger(index) ? index : this.decimalePipe.transform(index, '1.2-2');
    }
}

