import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../core/services/app-config.service';
import { AuthService } from '../../core/services/auth.service';
import { Constants } from '../../core/services/constants.service';
import { MobileService } from '../../core/services/mobile.service';
import { StorageService } from '../../core/services/storage.service';
import { NotificationService } from '../../features/locataire/notifications/services/notification.service';
import { NotificationsShareService } from '../../shared/services/notifications-share.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class AppNotificationsComponent implements OnInit, OnDestroy {
    mobileQuery: MediaQueryList;
    rsc: any;
    nbNotifNonLues = 0;

    currentNbNotif: number;
    subscription: Subscription;

    @Input() numeroTiers?: string;

    /**
     * Build an instance of AppNotificationsComponent.
     * @param {AppConfig} appConfig
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param mobileService
     * @param router
     * @param authService
     * @param storageService
     * @param constants
     * @param notificationService
     * @param locataireService
     * @memberof AppNotificationsComponent
     */
    constructor(
        private appConfig: AppConfig,
        private changeDetectorRef: ChangeDetectorRef,
        private mobileService: MobileService,
        private notificationService: NotificationService,
        private router: Router,
        private authService: AuthService,
        private notifShareService: NotificationsShareService,
        private storageService: StorageService,
        private constants: Constants
    ) {
        this.mobileQuery = this.mobileService.mobileQueryListener(this.changeDetectorRef, '768');
    }

    ngOnInit() {
        this.rsc = this.appConfig.rsc.layout.header.notifications;
        this.subscription = this.notifShareService.currentNumber.subscribe(number => this.currentNbNotif = number);

        if (this.authService.isLoggedAs()) {
            const user = this.storageService.getFromSessionStorage(this.constants.APP_USER);
            this.numeroTiers = user.numero_Tiers;
        }

        this.notificationService.getNotification().subscribe(notif => {
            if (notif !== null && notif !== undefined) {
                this.nbNotifNonLues = notif.nbNonLues;
                this.notifShareService.setNbNotif(this.nbNotifNonLues);
            }
        });
    }

    /**
     * Redirige vers l'écran principal -> tableau-de-bord
     *
     * @memberof AppNotificationsComponent
     */
    onClick(): void {
        this.router.navigate([this.authService.getBaseUrl(), this.rsc.url]);
    }

    /**
     * Supprime le mobile query listener à la destruction du composant
     *
     * @memberof AppNotificationsComponent
     */
    ngOnDestroy(): void {
        this.mobileService.removeListener(this.mobileQuery);
        this.subscription.unsubscribe();
    }
}
