import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class MobileService {

    private _mobileQueryListener: () => void;

    /**
     * Build an instance of MobileService.
     * @param {MediaMatcher} media
     * @memberof MobileService
     */
    constructor(
        private media: MediaMatcher,
    ) {
    }

    /**
     * S'abonne au mobile query listener
     *
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {string} maxWidth
     * @returns {MediaQueryList}
     * @memberof MobileService
     */
    mobileQueryListener(changeDetectorRef: ChangeDetectorRef, maxWidth: string): MediaQueryList {
        const isMobileMedia: MediaQueryList = this.media.matchMedia(`(max-width: ${maxWidth}px)`);
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        isMobileMedia.addListener(this._mobileQueryListener);
        return isMobileMedia;
    }

    /**
     * Détruit le mobile query listener
     *
     * @param {MediaQueryList} isMobileMedia
     * @memberof MobileService
     */
    removeListener(isMobileMedia: MediaQueryList): void {
        isMobileMedia.removeListener(this._mobileQueryListener);
    }
}
