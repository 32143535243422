import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from '../../../core/services/app-config.service';

@Component({
    selector: 'app-spinner-and-error',
    templateUrl: './spinner-and-error.component.html',
    styleUrls: ['./spinner-and-error.component.scss']
})
export class SpinnerAndErrorComponent implements OnInit {

    @Input() noCard: boolean;
    @Input() spinnerColor: string;
    @Input() spinnerSize: number;

    @Input() isLoading: boolean;
    @Input() loadingMessage: string;
    @Input() showDefaultLoadingMessage: boolean;

    @Input() isError: boolean;
    @Input() errorMessage: string;

    rsc: any;
    errorTitle: string;

    /**
     * Build an instance of SpinnerAndErrorComponent
     * 
     * @param AppConfig appConfig
     * 
     */
    constructor(
        private appConfig: AppConfig
    ) {
    }

    /**
     * Initializes LoadingAndErrorComponent
     *
     * @memberof LoadingAndErrorComponent
     */
    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.shared.spinner;
        this.errorTitle = this.rsc.errorTitle;
    }

    /**
     * Retourne la taille du spinner en fonction de l'input spinnerSize
     * Si aucune taille n'est donnée en entrée retourne 3x par défaut
     *
     * @memberof LoadingAndErrorComponent
     */
    getSpinnerSize(): string {
        if (this.spinnerSize && this.spinnerSize > 1 && this.spinnerSize < 10) {
            return 'fa-' + this.spinnerSize + 'x';
        }
        return 'fa-3x';
    }

    /**
     * Retourne la couleur du spinner en fonction de l'input spinnerColor
     * Si aucune couleur n'est donnée en entrée retourne primary par défaut
     *
     * @memberof LoadingAndErrorComponent
     */
    getSpinnerColor(): string {
        return this.spinnerColor ? this.spinnerColor + '-spinner' : 'spinner-error-primary-spinner';
    }

    /**
     * Retourne le message de chargement fourni ou bien
     * 'Chargement en cours' si showDefaultLoadingMessage est activé
     *
     * @memberof LoadingAndErrorComponent
     */
    getLoadingMessage(): string {
        let message;
        if (this.showDefaultLoadingMessage || this.loadingMessage) {
            message = this.loadingMessage ? this.loadingMessage : this.rsc.loadingMessage;
        }
        return message;
    }

    /**
     * Retourne le message d'erreur fourni ou bien 'Une erreur inattendue s'est produite'
     * s'il n'y en a pas
     *
     * @memberof LoadingAndErrorComponent
     */
    getErrorMessage(): string {
        return this.errorMessage ? this.errorMessage : this.rsc.errorMessage;
    }
}
