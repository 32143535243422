import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

// RxJS
import { Observable, Subject } from 'rxjs';
import { MaintenanceService } from '../../shared/services/maintenance.service';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate, CanActivateChild {
    isMaintenanceOn: boolean;

    /**
     * Build an instance of MaintenanceGuard.
     * @param {Router} router
     * @param {MaintenanceService} maintenanceService
     * 
     * @memberof MaintenanceGuard
     */
    constructor(
        public router: Router,
        private maintenanceService: MaintenanceService,
    ) {
    }

    /**
     * Indicates if a route can be activated
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     * @memberof MaintenanceGuard
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkMaintenance();
    }

    /**
     * Indicates if a child route can be activated
     *
     * @param {ActivatedRouteSnapshot} childRoute
     * @param {RouterStateSnapshot} state
     * @returns {(boolean | Observable<boolean> | Promise<boolean>)}
     * @memberof MaintenanceGuard
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkMaintenance();
    }

    /**
     * Check if maintenance is on
     *
     * @returns {Observable<boolean>}
     * @memberof MaintenanceGuard
     */
    checkMaintenance(): Observable<boolean> {
        var subject = new Subject<boolean>();

        this.maintenanceService.getMaintenanceParamByLocataire().subscribe(params => {
            if (params && params.enabled === true) {
                this.router.navigate(['/maintenance']);
                subject.next(false);
            }
            subject.next(true);
        });
        return subject.asObservable();
    }
}
