import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppConfig } from '../../core/services/app-config.service';
import { Constants } from '../../core/services/constants.service';
import { Contrat } from '../models/contrat';
import { HistoriquePaiement } from '../models/historique-paiement';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Locataire } from '../models/locataire';
import { LocataireService } from './locataire.service';
import { Paiement } from '../models/paiement';
import { Router } from '@angular/router';
import { StorageService } from '../../core/services/storage.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContratService {

    readonly idDefaultValue: number = 0;

    constructor(
        private http: HttpClient,
        public router: Router,
        private appConfig: AppConfig,
        private locataireService: LocataireService,
        private constants: Constants,
        private storageService: StorageService,
    ) { }

    /**
     * Appelle l'API retournant tous les contrats du locataire
     * étant donné son numéro tiers
     *
     * @returns {Observable<Contrat[]>}
     * @memberof ContratService
     */
    getLocataireContrats(numeroTiers?: string): Observable<Contrat[]> {
        const param = numeroTiers || numeroTiers !== undefined ? `${numeroTiers}` : '';

        return this.http
            .get(`${environment.apiUrl?.protected}/contrat/${param}`)
            .pipe(map((user: Locataire) => user.cAs));
    }

    /**
     * Appelle l'API retournant un objet complet Locataire avec 
     * tous les contrats associés
     *
     * @returns {Observable<Locataire>}
     * @memberof ContratService
     */
    getLocataireContrat(numeroTiers?: string): Observable<Locataire> {
        const param = numeroTiers || numeroTiers !== undefined ? `${numeroTiers}` : '';
        return this.http
            .get(`${environment.apiUrl?.protected}/contrat/${param}`)
            .pipe(map((user: Locataire) => user));
    }

    /**
     * Appelle l'API retournant tous les contrats du locataire uniquement
     *
     * @returns {Observable<Locataire>}
     * @memberof ContratService
     */
    getContrats(numeroTiers?: string): Observable<Contrat[]> {
        const param = numeroTiers || numeroTiers !== undefined ? numeroTiers : '';

        return this.locataireService.getLocataire().pipe(
            switchMap(() => {
                return this.getLocataireContrats(param).pipe(map(contrats => {
                    return contrats;
                }));
            }));
    }

    /**
     * Appelle l'API pour savoir si le locataire a des contrats, et si oui si il en a au moins un actif ou avec un solde positif
     *
     * @returns {Observable<Boolean>}
     * @memberof ContratService
     */
    hasContratsActifs(): Observable<boolean> {
        const isLoggedAs = this.storageService.getFromSessionStorage(this.constants.LOGGED_AS_LOCATAIRE);

        return this.locataireService.getLocataire().pipe(
            switchMap((user) => {
                const param = isLoggedAs ? user.numero_Tiers : '';

                return this.getLocataireContrats(param).pipe(map(contrats => {
                    if (contrats.length === 0) return false;
                    else {
                        const activeOrPositiveSolde = (contrat) => contrat.contrat_actif > 0 || contrat.solde !== 0;
                        return contrats.some(activeOrPositiveSolde);
                    }
                }),
                    catchError(e => of(false)));
            }));
    }

    /**
     * Appelle l'API retournant le locataire avec les contrats associés
     *
     * @returns {Observable<Locataire>}
     * @memberof ContratService
     */
    getLocataireWithContrats(): Observable<Locataire> {
        const isLoggedAs = this.storageService.getFromLocalStorage(this.constants.LOGGED_AS_LOCATAIRE);

        return this.locataireService.getLocataire().pipe(
            switchMap(user => {
                const param = isLoggedAs ? user.numero_Tiers : '';

                return this.getLocataireContrats(param).pipe(map(contrats => {
                    user.cAs = contrats;
                    return user as Locataire;
                }));
            }));
    }

    /**
     * Appelle l'API retournant l'historique des paiements
     * du contrat dont l'id est passé en paramètre
     *
     * @param {HistoriquePaiement} hitoriquePaiementBody
     * @returns {Observable<any>}
     * @memberof ContratService
     */
    getContratPaiements(hitoriquePaiementBody: HistoriquePaiement): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/Historic_Paiement/CA`, hitoriquePaiementBody);
    }

    /**
     * Récupère l'ID du contrat précédement sélectionné par l'utilisateur
     * et stocké en session
     *
     * @returns {number}
     * @memberof ContratService
     */
    getSelectedContratId(): number {
        const idContratActuel = this.storageService.getFromSessionStorage(this.constants.ID_CONTRAT_KEY);
        if (idContratActuel && typeof idContratActuel === 'number') {
            return idContratActuel;
        } else {
            return this.idDefaultValue;
        }
    }

    /**
     * Récupère le contrat précédement sélectionné par l'utilisateur
     * et stocké en session
     *
     * @param {Contrat[]} allContrat
     * @returns {Contrat}
     * @memberof ContratService
     */
    getSelectedContrat(allContrat: Contrat[]): Contrat {
        const idContratActuel = this.storageService.getFromSessionStorage(this.constants.ID_CONTRAT_KEY);
        if (idContratActuel && typeof idContratActuel === 'number') {
            return this.getContratById(allContrat, idContratActuel);
        } else {
            return undefined;
        }
    }

    /**
     * Récupère le contrat correspondant à l'ID fourni en paramètres
     * dans la liste des contrats fournie
     *
     * @param {Contrat[]} allContrats
     * @param {number} contratId
     * @returns {Contrat}
     * @memberof ContratService
     */
    getContratById(allContrats: Contrat[], contratId: number): Contrat {
        return allContrats.find(element => element.id_CA == contratId);
    }


    /**
     * Récupération de l'adresse du contrat, via le lot principal s'il existe
     * Sinon retourne l'adresse CA
     *
     * @param {Contrat} contrat
     * @returns {string}
     * @memberof ContratsComponent
     */
    getLibelleContrat(contrat: Contrat): string {
        if (contrat) {
            return `${contrat.adresse_CA || ''} ${contrat.code_Postal_CA || ''} ${contrat.commune_CA || ''}`
        } else {
            return;
        }
    }

    /**
     * Récupère le numéro du contrat
     *
     * @param {Contrat} contrat
     * @returns {string}
     * @memberof ContratsComponent
     */
    getNumeroContrat(contrat: Contrat): string {
        if (contrat && contrat.numero_CA) {
            return `N°${contrat.numero_CA}`;
        }
        return undefined;
    }

    /**
     * Modifie la liste des contrats en ajoutant les deux filtres :
     * si le contrat a un statut actif OU si le solde est différent de 0
     *
     * @param {Array<Contrat>} contrats
     * @returns {Array<Contrat>}
     * @memberof ContratService
     */
    filterContrats(contrats: Array<Contrat>): Array<Contrat> {
        const filteredContrats: Array<Contrat> = [];
        if (contrats) {
            contrats.forEach(contrat => {
                if (contrat.contrat_actif === 1 || contrat.solde !== 0) {
                    filteredContrats.push(contrat);
                }
            });
        }

        return filteredContrats;
    }

    /**
     * Cherche un contrat parti ou un contrat actif dans la liste
     * des contrats en fonction de la valeur passée en paramètre
     *
     * @param {boolean} estPartiValue 
     * @param {Array<Contrat>} contrats
     * @returns {Contrat | undefined}
     * @memberof ContratService
     */
    findContratParti(estPartiValue: boolean, contrats: Contrat[]): Contrat | undefined {
        return contrats.find(contrat => contrat.estParti === estPartiValue);
    }

    getMockHistoriquePaiements(): Array<Paiement> {
        return [
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '157856',
                'datePaiement': new Date('2018-10-09T00:00:00'),
                'montantPaiement': 400,
                'idAutorisation': '0',
                'idTransaction': '210030',
                'resultRetourPaiement': false,
                'statut': 'Erreur'
            },
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '163285',
                'datePaiement': new Date('2018-11-06T00:00:00'),
                'montantPaiement': 400,
                'idAutorisation': '0',
                'idTransaction': '216271',
                'resultRetourPaiement': false,
                'statut': 'Erreur'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '173821',
                'datePaiement': new Date('2018-12-27T00:00:00'),
                'montantPaiement': 455,
                'idAutorisation': '242984',
                'idTransaction': '228517',
                'resultRetourPaiement': false,
                'statut': 'En cours'
            },
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '172543',
                'datePaiement': new Date('2018-12-16T00:00:00'),
                'montantPaiement': 350.74,
                'idAutorisation': '115054',
                'idTransaction': '227019',
                'resultRetourPaiement': false,
                'statut': 'En cours'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '235503',
                'datePaiement': new Date('2019-09-02T00:00:00'),
                'montantPaiement': 1082.09,
                'idAutorisation': '261361',
                'idTransaction': '299231',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '226560',
                'datePaiement': new Date('2019-07-26T00:00:00'),
                'montantPaiement': 812.85,
                'idAutorisation': '425638',
                'idTransaction': '288981',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '249240',
                'datePaiement': new Date('2019-10-15T00:00:00'),
                'montantPaiement': 833.36,
                'idAutorisation': '151495',
                'idTransaction': '314825',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '155641',
                'datePaiement': new Date('2018-10-03T00:00:00'),
                'montantPaiement': 428.91,
                'idAutorisation': '673246',
                'idTransaction': '207407',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '163286',
                'datePaiement': new Date('2018-11-06T00:00:00'),
                'montantPaiement': 400,
                'idAutorisation': '155621',
                'idTransaction': '216274',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '180572',
                'datePaiement': new Date('2019-01-20T00:00:00'),
                'montantPaiement': 405.74,
                'idAutorisation': '684153',
                'idTransaction': '236340',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '188635',
                'datePaiement': new Date('2019-02-26T00:00:00'),
                'montantPaiement': 340.85,
                'idAutorisation': '161675',
                'idTransaction': '245795',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '194678',
                'datePaiement': new Date('2019-03-12T00:00:00'),
                'montantPaiement': 872.85,
                'idAutorisation': '244558',
                'idTransaction': '252628',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '125835',
                'datePaiement': new Date('2018-08-20T00:00:00'),
                'montantPaiement': 127.54,
                'idAutorisation': '865751',
                'idTransaction': '146283',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '000005328',
                'kcidpaie': '180257',
                'datePaiement': new Date('2019-01-17T00:00:00'),
                'montantPaiement': 400,
                'idAutorisation': '284544',
                'idTransaction': '235946',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '188632',
                'datePaiement': new Date('2019-02-26T00:00:00'),
                'montantPaiement': 412,
                'idAutorisation': '028158',
                'idTransaction': '245790',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '216473',
                'datePaiement': new Date('2019-06-11T00:00:00'),
                'montantPaiement': 953.55,
                'idAutorisation': '017815',
                'idTransaction': '277505',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '208746',
                'datePaiement': new Date('2019-05-10T00:00:00'),
                'montantPaiement': 1500,
                'idAutorisation': '641344',
                'idTransaction': '268694',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '232676',
                'datePaiement': new Date('2019-08-14T00:00:00'),
                'montantPaiement': 612.85,
                'idAutorisation': '654042',
                'idTransaction': '295923',
                'resultRetourPaiement': false,
                'statut': 'OK'
            },
            {
                'ca': 882030,
                'numeroTiers': '006437457',
                'kcidpaie': '163297',
                'datePaiement': new Date('2018-11-06T00:00:00'),
                'montantPaiement': 605.74,
                'idAutorisation': '661116',
                'idTransaction': '216287',
                'resultRetourPaiement': false,
                'statut': 'OK'
            }
        ];
    }
}
