import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificationsShareService {
    private counter: number = 0;

    private nbNotifSource = new BehaviorSubject(this.counter);
    private selectedFilterSource = new BehaviorSubject(false);
    private selectedFilterEditSource = new BehaviorSubject(false);
    private nbLocataireValidesSource = new BehaviorSubject<number>(null);
    private nbLocataireRejetsSource = new BehaviorSubject<number>(null);
    private pageEditSource = new BehaviorSubject(false);
    private locataireLoadedSource = new BehaviorSubject<boolean>(false);
    private notificationMoreSource = new BehaviorSubject<boolean>(false);
    private idNotificationSource = new BehaviorSubject<number>(null);

    public currentNumber = this.nbNotifSource.asObservable();
    public currentSelectedFilter = this.selectedFilterSource.asObservable();
    public currentSelectedFilterEdit = this.selectedFilterEditSource.asObservable();
    public currentNbLocataireValides = this.nbLocataireValidesSource.asObservable();
    public currentNbLocataireRejets = this.nbLocataireRejetsSource.asObservable();
    public currentEditPage = this.pageEditSource.asObservable();
    public currentLoadedLocataire = this.locataireLoadedSource.asObservable();
    public currentNotificationMore = this.notificationMoreSource.asObservable();
    public currentIdNotification = this.idNotificationSource.asObservable();

    constructor() {
        // Empty constructor
    }

    /**
     * Set le nombre de notifications initiale
     *
     * @memberof NotificationsShareService
     */
    setNbNotif(nbNotif: number) {
        this.nbNotifSource.next(nbNotif);
    }

    /**
     * Decrementer le nombre de notifications
     *
     * @memberof NotificationsShareService
     */
    decrementNbNotif(nbNotif: number, delta: number) {
        this.nbNotifSource.next(nbNotif - delta);
    }

    /**
     * Récupére un boolean qui définit si des filtres sont sélectionnés
     * sur la page création d'une notification 
     * 
     *  @param {boolean} isSelected 
     *  @memberof NotificationsShareService
     */
    isSelectedFilter(isSelected: boolean) {
        this.selectedFilterSource.next(isSelected);
    }

    /**
     * Récupére un boolean qui définit si des filtres sont sélectionnés
     * sur la page modification d'une notification 
     * 
     *  @param {boolean} isSelected 
     *  @memberof NotificationsShareService
     */
    isSelectedFilterEdit(isSelected: boolean) {
        this.selectedFilterEditSource.next(isSelected);
    }

    /**
     * Récupére le nombre de numero tiers valides après l'appel à l'API
     * 
     *  @param {number} nbValides 
     *  @memberof NotificationsShareService
     */
    getNbLocatairesValides(nbValides: number) {
        this.nbLocataireValidesSource.next(nbValides);
    }

    /**
     * Récupére le nombre de numero tiers rejetés après l'appel à l'API
     * 
     *  @param {number} nbRejets
     *  @memberof NotificationsShareService
     */
    getNbLocatairesRejets(nbRejets: number) {
        this.nbLocataireValidesSource.next(nbRejets);
    }

    /**
     * Récupére un boolean qui définit si on se trouve
     * sur la page de modification des notifications
     * 
     *  @param {boolean} onEditPage
     *  @memberof NotificationsShareService
     */
    onEditPage(onEditPage: boolean) {
        this.pageEditSource.next(onEditPage);
    }

    /**
     * Récupére un boolean qui définit si les locataires
     * ont été chargé par un fichier csv
     * 
     *  @param {boolean} isLoaded
     *  @memberof NotificationsShareService
     */
    isLocataireLoaded(isLoaded: boolean): void {
        this.locataireLoadedSource.next(isLoaded);
    }

    /**
     * Récupére un boolean au clic sur le bouton
     * en savoir plus
     * 
     * @param {boolean} isClicked
     * @memberof NotificationsShareService
     */
    isNotificationMore(isClicked: boolean): void {
        this.notificationMoreSource.next(isClicked);
    }

    /**
     * Récupére l'id de la notification
     * au clic sur le bouton en savoir plus
     * 
     * @param {number} idNotif 
     * @memberof NotificationsShareService
     */
    setNotificationId(idNotif: number): void {
        this.idNotificationSource.next(idNotif);
    }
}
