import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../../core/services/app-config.service';
import { DataSharedService } from '../../services/data-shared.service';
import { MaintenanceService } from '../../services/maintenance.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit {
    label: string;
    message: string;
    rsc: any;

    /**
     * Build an instance of MaintenanceComponent.
     * 
     * @param AppConfig appConfig
     * @param Router router
     * @param MaintenanceService maintenanceService
     * @param DataSharedService dataSharedService
     * 
     * @memberof MaintenanceComponent
     */
    constructor(
        public router: Router,
        private appConfig: AppConfig,
        private maintenanceService: MaintenanceService,
        private dataSharedService: DataSharedService
    ) {
    }

    ngOnInit() {
        this.setRessources();
        this.maintenanceService.getMaintenanceParamByLocataire().subscribe(params => {
            if (params && params.enabled === true) {
                this.label = this.rsc.labelMaintenanceLocataire;
                this.message = params.message;
            }
        });
        this.dataSharedService.currentErrorMaintenance.subscribe(isError => {
            if (isError) {
                this.label = this.rsc.labelMaintenanceLocataire;
                this.message = this.rsc.messageMaintenanceLocataire;
            }
        })
    }

    /**
     * Définit les ressources du component
     * 
     * 
     * @memberof MaintenanceComponent
     */
    setRessources(): void {
        this.rsc = this.appConfig.rsc.admin.parameters.maintenance;
    }

}
