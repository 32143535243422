import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../../core/services/error.service';
import { Cgu } from '../../features/admin/parametrages/models/cgu';
import { MajCgu } from '../../features/admin/parametrages/models/maj-cgu';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CguService {

    /**
     * Build an instance of CguService
     * @param http: HttpClient
     * @param errorService: ErrorService
     */
    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) {
    }

    /**
    * Récupérer les informations de CGU
    * @returns {any}
    */
    getCguParam(): Observable<Cgu> {
        return this.http
            .get(`${environment.apiUrl?.protected}/AdminParam/GetCguParam`)
            .pipe(map((params: Cgu) => params),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
    * Récupérer le texte des CGUs
    * @returns {any}
    */
    getCguPublic(): Observable<any> {
        return this.http
            .get(`${environment.apiUrl?.public}/GetCGU`)
            .pipe(map((params: any) => params),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
    * Fait appel à l'api de mise à jour de CGU par admin
    */
    majCgu(cgu: MajCgu): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/AdminParam/UpdateCguParam`, cgu);
    }
}
