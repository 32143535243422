import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
// Services
import { AuthService } from '../services/auth.service';
import { Constants } from '../services/constants.service';
import { StorageService } from '../services/storage.service';



@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild {

    /**
     * Build an instance of AdminGuard.
     * @param {Router} router
     * @param authService
     * @memberof AdminGuard
     */
    constructor(
        public router: Router,
        private authService: AuthService,
        private storageService: StorageService,
        private constants: Constants
    ) {
    }

    /**
     * Indicates if a route can be activated
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {boolean}
     * @memberof AdminGuardService
     */
    canActivate(): boolean {
        const auth = this.storageService.getFromSessionStorage(this.constants.APP_AUTH);
        const body = {
            pageNumber: 1,
            rowsPerPage: 200,
            filter: auth.account.username
        }

        this.authService.listerUtilisateurs(body).subscribe(
            res => {
                if (res && res.data) {
                    const droitAdmin = res.data.find(element => element.droitAdmin === true);
                    const droitEnTantQue = res.data.find(element => element.droitEnTantQue === true);
                    const droitNotification = res.data.find(element => element.droitNotification === true);

                    if (droitAdmin === undefined && droitEnTantQue) {
                        this.router.navigate(['admin/locataires']);
                    } else if (droitAdmin === undefined && droitNotification) {
                        this.router.navigate(['admin/notifications']);
                    } else if (
                        droitAdmin === undefined
                        && droitNotification === undefined
                        && droitEnTantQue === undefined
                    ) {
                        this.router.navigate(['admin/aucun-droit']);
                    }
                }
            });

        return this.checkLogin();
    }

    /**
     * Indicates if a child route can be activated
     *
     * @param {ActivatedRouteSnapshot} childRoute
     * @param {RouterStateSnapshot} state
     * @returns {(boolean | Observable<boolean> | Promise<boolean>)}
     * @memberof AdminGuard
     */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkLogin();
    }

    /**
     * Check if user is logged in
     *
     * @returns {boolean}
     * @memberof AuthGuardService
     */
    checkLogin(): boolean {
        if (this.authService.isAdmin() && (this.authService.isLoggedIn() || this.authService.browserRefreshed)) {
            return true;
        }

        // Navigate to the login page with extras
        this.router.navigate(['authentication', 'login']);
        return false;
    }
}
