@if (pageTitle) {
<mat-toolbar-row class="navbar-header">
    @if (pageTitle.icon) {
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="4%">
        <app-icon tabindex="0" title="Logo ICF haut de page, cliquez pour revenir au tableau de bord"
            [class]="'header-icon'" [iconType]="pageTitle.iconType" [icon]="pageTitle.icon"></app-icon>
        <span [ngClass]="{'header-title-mobile-small': isMobileSmall.matches && !isTitleSmall}" tabindex="0"
            title="titre de la page">{{ pageTitle.title }}</span>
    </div>
    }
</mat-toolbar-row>
}