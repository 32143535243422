import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import packageJson from '../../../package.json';
import { AppConfig } from '../core/services/app-config.service';
import { AuthService } from '../core/services/auth.service';
import { Constants } from '../core/services/constants.service';
import { MobileService } from '../core/services/mobile.service';
import { StorageService } from '../core/services/storage.service';
import { ContratService } from '../shared/services/contrat.service';
import { LocataireService } from '../shared/services/locataire.service';
import { _configOrganismes } from '../accessibility/config/organismes-locataire';
import { Subject, Subscription } from 'rxjs';
import { DataSharedService } from '../shared/services/data-shared.service';
import { infosOrganismeLocataire } from '../shared/models/infosOrganismeLocataires';
import { Contrat } from '../shared/models/contrat';
import { Locataire } from '../shared/models/locataire';
import { MixinService } from '../core/services/mixin.service';


/** @title Responsive sidenav */
@Component({
    selector: 'app-layout',
    templateUrl: 'layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
    mobileQuery: MediaQueryList;
    dark: boolean;
    minisidebar: boolean;
    showHide: boolean;
    hasContratsActifs: boolean;
    sidebarOpened;
    rsc: any;
    rscAdmin: any;
    appVersion: string;

    userEmail: string = '';
    userName: string;
    numeroTiers: string;
    isFirstConnection: boolean = true;

    sourceLogo: string;
    onLoadedLogo: Subject<boolean> = new Subject<boolean>();
    isLogoLoading: boolean = true;
    infosOrganismeLocataire: infosOrganismeLocataire;

    @ViewChild(ToastContainerDirective, { static: true })
    private toastContainer: ToastContainerDirective;

    public config: PerfectScrollbarConfigInterface = {};

    onSelectedContrat: Subscription;
    isLoadedContrat: boolean = false;
    version: string;

    /**
     * Build an instance of AppLayoutComponent.
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {AppConfig} appConfig
     * @param {ToastrService} toastrService
     * @param {MobileService} mobileService
     * @param {ContratService} contratService
     * @param {Router} router
     * @param {AuthService} authService
     * @param {StorageService} storageService
     * @param {Constants} constants
     * @param {LocataireService} locataireService
     * @param {ContratsService} contratService
     * @param {DataSharedService} dataSharedService
     * @param {MixinService} mixinService
     * 
     * @memberof AppLayoutComponent
     */
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private appConfig: AppConfig,
        private toastrService: ToastrService,
        private mobileService: MobileService,
        private router: Router,
        private authService: AuthService,
        private storageService: StorageService,
        private constants: Constants,
        private locataireService: LocataireService,
        private contratService: ContratService,
        private dataSharedService: DataSharedService,
        private mixinService: MixinService
    ) {
        this.mobileQuery = this.mobileService.mobileQueryListener(this.changeDetectorRef, '768');
        this.version = packageJson.version;
    }

    ngOnInit() {
        this.resetSourceLogo();
        this.setLogoBySelectedContrat();
        this.appVersion = "Version : " + this.version;
        this.rsc = this.appConfig.rsc.layout;
        this.rscAdmin = this.appConfig.rsc.admin.locataires;
        this.toastrService.overlayContainer = this.toastContainer;
        if (this.isLoggedAs()) {
            this.isFirstConnection = false;
            this.userEmail = this.storageService.getFromSessionStorage(this.constants.APP_USER_EMAIL_LOGGED_AS);
        }
        if (!this.isAdmin()) {
            this.hasContratsActifs = this.router.url !== this.constants.URL_AUCUN_CONTRAT;
            this.userEmail = this.storageService.getFromSessionStorage(this.constants.APP_USER_EMAIL);
            this.locataireService.getLocataire().subscribe(loc => {
                this.resetSourceLogo();
                if (loc.connected === false) {
                    this.isFirstConnection = true;
                    this.locataireService.updateFirstConnect().subscribe(() => {
                        /**
                         * A la première connexion - on affiche la vidéo de présentation,
                         * en même temps il faut mettre à jour le locataire (set "connected" à true),
                         * car le premier appel de getLocataire() stocke le locataire reçu du back 
                         * dans le session storage. Afin de sauvegarder le bon - où "connected" 
                         * est mis à jour à true, il faut rappeler l'api /locataire
                         */
                        this.locataireService.setLocataire().subscribe(() => {
                            this.router.navigate(
                                [`/${this.rsc.redirectUrl.presentation}`],
                                { queryParams: { redirect: this.rsc.redirectUrl.dashbord } }
                            );
                        });
                    });
                } else if (loc.connected === true) {
                    this.isFirstConnection = false;
                    this.userName = `${loc.prenom || ''} ${loc.nom || ''}`;
                    this.numeroTiers = loc.numero_Tiers;
                    this.contratService.getLocataireContrats(this.numeroTiers).subscribe(contrats => {
                        if (contrats) {
                            this.setSourceLogoAvecContrats(contrats);
                        }
                    },
                        (error) => {
                            if (error) {
                                this.setSourceLogoSansContrats(loc);
                            }
                        });
                }
            });
        } else {
            this.isFirstConnection = false;
            this.sourceLogo = this.appConfig.rsc.admin.logo.path;
            this.isLogoLoading = false;
        }
    }

    /**
     * Définit le logo en fonction du contrat
     * sélectionné
     * 
     * @memberof LayoutComponent
     */
    setLogoBySelectedContrat(): void {
        this.onSelectedContrat = this.dataSharedService.currentContratSelected.subscribe((isSelected: boolean) => {
            if (isSelected) {
                const codeOrganismeLocataire = this.mixinService.getCodeOrganismeLocataire();
                const infosLocataireOrganisme = _configOrganismes.find(item => item.code === codeOrganismeLocataire);

                if (infosLocataireOrganisme) {
                    this.sourceLogo = infosLocataireOrganisme.path + infosLocataireOrganisme.logo;
                    this.isLogoLoading = false;
                }

                this.isLoadedContrat = true;
            }
        });
    }

    /**
     * Définit le logo pour un locataire avec contrat
     * 
     * @param {Contrat[]} contrats 
     * 
     * @memberof LayoutComponent
     */
    setSourceLogoAvecContrats(contrats: Contrat[]): void {
        this.findInfosOrganismeLocataire(contrats);
        this.storageService.storeInSessionStorage(this.constants.APP_USER_ORGANISME, this.infosOrganismeLocataire.code);

        if (this.infosOrganismeLocataire && contrats) {
            this.sourceLogo = this.infosOrganismeLocataire.path + this.infosOrganismeLocataire.logo;
            this.onLoadedLogo.next(true);
            this.isLogoLoading = false;
            this.dataSharedService.isMobileLogoLoaded(true);
            this.isLoadedContrat = true;
        }
    }

    /**
     * Définit le logo pour un locataire sans contrat
     * 
     * @param {Locataire} loc 
     * 
     * @memberof LayoutComponent
     */
    setSourceLogoSansContrats(loc: Locataire): void {
        const labelOrganismeLocataire = loc.organisme;
        if (labelOrganismeLocataire) {
            const infosLocataireOrganisme = _configOrganismes.find(infosOrganisme => infosOrganisme.label === labelOrganismeLocataire);

            if (infosLocataireOrganisme) {
                this.storageService.storeInSessionStorage(this.constants.APP_USER_ORGANISME, infosLocataireOrganisme.code);
                this.sourceLogo = infosLocataireOrganisme.path + infosLocataireOrganisme.logo;
                this.onLoadedLogo.next(true);
                this.isLogoLoading = false;
                this.dataSharedService.isMobileLogoLoaded(true);
                this.isLoadedContrat = true;
            }
        }
    }

    /**
     * Récupére l'objet InfosOrganismeLocataire en fonction du code
     * organisme récupéré du session storage
     * 
     * @param {Contrat[]} contrats
     * 
     * @memberof LayoutComponent
     */
    findInfosOrganismeLocataire(contrats: Contrat[]): void {
        const idOrganisme = contrats[0].id_Organisme;
        this.infosOrganismeLocataire = _configOrganismes.find(infosOrganisme => infosOrganisme.id === idOrganisme);
    }

    isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    setMarginTitleHeader(): number {
        if (this.mobileQuery.matches) return 0;
        else {
            if (this.isAdmin()) return 200;
            else if (this.hasContratsActifs) return 100;
            else return 200;
        }
    }

    /**
     * Redirige vers l'écran principal -> tableau-de-bord
     *
     * @memberof LayoutComponent
     */
    onClick(): void {
        const userType = this.authService.getUserType();
        this.router.navigate([this.authService.getBaseUrl(), this.rsc.header.logoIcf[userType].url]);
    }

    onExit(): void {
        this.storageService.storeInSessionStorage(this.constants.APP_USER_EMAIL, this.storageService.getFromSessionStorage(this.constants.APP_ADMIN_EMAIL));
        this.removeData();

        this.router.navigate([this.rscAdmin.url]).then(() => {
            window.location.reload();
        });
    }

    /**
     * Supprime le mobile query listener à la destruction du composant
     *
     * @memberof AppLayoutComponent
     */
    ngOnDestroy(): void {
        this.mobileService.removeListener(this.mobileQuery);
    }

    /**
     * Vérifie si l'admin est connecté en tant que locataire
     * 
     * @returns boolean
     * @memberof AppLayoutComponent
     */
    isLoggedAs(): boolean {
        return this.authService.isLoggedAs();
    }

    /**
     * Supprime des données du locataire dans le session storage
     * 
     * 
     * @memberof AppLayoutComponent
     */
    removeData(): void {
        this.storageService.removeFromSessionStorage(this.constants.LOGGED_AS_LOCATAIRE);
        this.storageService.removeFromSessionStorage(this.constants.APP_USER);
        this.storageService.removeFromSessionStorage(this.constants.APP_USER_ID);
        this.storageService.removeFromSessionStorage(this.constants.APP_ADMIN_EMAIL);
        this.storageService.removeFromSessionStorage(this.constants.ID_CONTRAT_KEY);
        this.storageService.removeFromSessionStorage(this.constants.APP_USER_EMAIL_LOGGED_AS);
        this.storageService.removeFromSessionStorage(this.constants.APP_USER_ORGANISME);
        this.storageService.removeFromSessionStorage(this.constants.HABILITATIONS);
    }

    /**
     * Réinitialise la variable logo
     * 
     * @memberof LayoutComponent
     */
    resetSourceLogo(): void {
        this.sourceLogo = '';
    }
}
