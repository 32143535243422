import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { AppConfig } from '../../../core/services/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../core/services/storage.service';
import { Constants } from '../../../core/services/constants.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    rsc: any;
    buttons: any;

    /**
     * Build an instance of ErrorComponent.
     * @member ErrorComponent
     * @param authService
     * @param appConfig
     * @param router
     * @param activatedRoute
     * @param constants
     * @param storageService
     */
    constructor(
        private authService: AuthService,
        private appConfig: AppConfig,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private constants: Constants,
        private storageService: StorageService
    ) {
    }

    ngOnInit() {
        this.rsc = this.appConfig.rsc.pages.error;
        this.buttons = this.rsc.buttons;

        this.activatedRoute.data.subscribe(data => {
            if (data.is404) {
                this.rsc = this.appConfig.rsc.pages.error.notFound;
            }
        });
    }

    isUserConnected(): boolean {
        return this.authService.isLoggedIn() && this.storageService.getFromSessionStorage(this.constants.APP_USER_ID);
    }

    goToHomePage() {
        if (this.isUserConnected()) {
            this.router.navigate([this.authService.getBaseUrl()]);
        } else {
            this.authService.logout();
        }
    }
}
