<div class="ios-prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <div>
        {{ rsc.iosMessage1 }}
        <img src="/assets/images/icon/share-rounded.png" alt="Partager" class="ios_icon">
        {{ rsc.iosMessage2 }}
        <img src="/assets/images/icon/add_to_home_screen_icon.png" alt="Ajouter" class="ios_icon">
        {{ rsc.iosMessage3 }}
    </div>
</div>
