<!-- SPINNER SI LOADING -->
<app-spinner-and-error [isLoading]="isLoading" [loadingMessage]="rsc.loading"
    [isError]="isError"></app-spinner-and-error>

<!-- PLUSIEURS CONTRATS -->
@if (contrats && contrats.length > 1) {
<mat-form-field appearance="outline" class="contrats-select">
    <mat-select (selectionChange)="onSelect($event.value)" [(value)]="selectedID">
        @for ( contrat of contrats ; track contrat) {
        <mat-option [value]="contrat.id_CA">
            {{ getNumeroContrat(contrat) }} - {{ getLibelleContrat(contrat) | titlecase }}
        </mat-option>
        }
    </mat-select>
</mat-form-field>
}

<!-- CONTRAT UNIQUE -->
@if (contrats && contrats.length === 1) {
<mat-card appearance="outlined">
    <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2%">
            <i-feather class="contrats-icon" name="{{ rsc.iconContrat }}"></i-feather>
            <span>{{ getNumeroContrat(contrats[0]) }} - {{ getLibelleContrat(contrats[0]) | titlecase }}</span>
        </div>
    </mat-card-content>
</mat-card>
}