import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// Services
import { Logger } from '../services/logger.service';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppConfig } from '../services/app-config.service';
import { ErrorService } from '../services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    listAuthenticationError: number[] = [
        401, 403
    ]

    listCommonError: number[] = [
        400, 408, 413, 415, 429, 500, 502, 503, 504, 508
    ]

    constructor(
        // Le ErrorHandler étant créé avant les services, nous utilisons l'injecteur pour les récupérer
        private injector: Injector,
        private zone: NgZone,
        private appConfig: AppConfig
    ) {
    }

    /**
     * Intercepte et gère les erreurs coté client et serveur (erreurs JS ou erreurs HTTP)
     *
     * @memberof ErrorsHandler
     * @param resp
     */
    handleError(resp: Error | HttpErrorResponse): void {
        const router: Router = this.injector.get(Router);
        const logger: Logger = this.injector.get(Logger);
        const authService: AuthService = this.injector.get(AuthService);
        const errorService: ErrorService = this.injector.get(ErrorService);
        const toastr: ToastrService = this.injector.get(ToastrService);

        // Log the error anyway
        logger.error(resp);

        if (resp instanceof HttpErrorResponse) {
            // Erreur HTTP => affichage d'une popup avec le message "user-friendly"
            // Erreur serveur ou erreur de connexion
            if (!navigator.onLine) {
                // Pas de connexion à internet
                this.zone.run(() => toastr.warning(this.appConfig.rsc.error.noInternet));
            } else {
                if (router.url !== 'login' && this.listAuthenticationError.includes(resp.status)) {
                    // Erreur HTTP (error.status === 401, 403)
                    // Déconnexion
                    this.zone.run(() => authService.logout());
                }

                if (this.listCommonError.includes(resp.status)) {
                    // On affiche une alerte à l'utilisateur
                    this.zone.run(() => toastr.error(errorService.getErrorMessage(resp)));
                }
            }
        } else {
            // Erreur JS coté client (Angular Error, ReferenceError...)
            // On redirige l'utilisateur vers la page d'erreur
            this.zone.run(() => router.navigate(['/error']));
        }
    }
}
