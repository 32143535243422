import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../../../../core/services/error.service';
import { NotificationsLocataire } from '../../../../shared/models/notificationsLocataire';
import { AppPush } from '../../../../shared/models/push-entity';
import { environment } from '../../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    /**
     * Build an instance of NotificationService.
     * @param http: HttpClient
     * @param errorService: ErrorService
     */
    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) {
    }

    /**
    * Enregistre l'utilisateur pour les notifications push
    */
    addPushNotifications(push: AppPush): Observable<any> {
        return this.http.put(`${environment.apiUrl?.protected}/AddNotifPush`, push);
    }

    /**
     * Appelle l'API pour récupérer les notifications d'un locataire
     * 
     * @param {string} codeTier
     * @memberof NotificationService
     */
    getNotification(numeroTiers?: string): Observable<any> {
        const param = numeroTiers || numeroTiers !== undefined ? `${numeroTiers}` : '';

        return this.http.get(`${environment.apiUrl?.protected}/GetNotifLocataire/${param}`)
            .pipe(
                map((resp) => resp),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
     * Appelle l'API pour récupérer le détail d'une notification
     * 
     * @param {string} idNotification
     * @memberof NotificationService
     */
    getNotificationDetail(idNotification: string): Observable<any> {
        return this.http.get(`${environment.apiUrl?.protected}/GetNotification/${idNotification}`)
            .pipe(
                map((resp) => resp),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
     * Appelle l'API pour changer le status d'une notification
     * 
     * @param {idNotification: number, codeTiers: string, status: number} body
     * @memberof NotificationService
     */
    patchNotificationStatus(body: { idNotification: number, codeTiers: string, status: number }): Observable<any> {
        return this.http.patch(`${environment.apiUrl?.protected}/UpdateNotificationLocataireStatus`, body).pipe(
            map((resp) => { return resp; })
        );
    }

    getMockNotifications(): NotificationsLocataire {
        return {
            nbNonLues: 2,
            codeTiers: '000580535',
            notifications: [
                {
                    id_Notification_Locataires: 1,
                    id_Notification: 1,
                    code_tiers: '000580535',
                    syS_HASH: '8ds454z84qd4cxs4qdz4qx2q4zdz4q24s',
                    syS_DATE_INSERTION: new Date('2021-02-01T00:00:00'),
                    syS_DATE_MAJ: new Date('2021-02-01T00:00:00'),
                    lue_Non_Lue: 0,
                    id_TypesNotification: 3,
                    title_Notification: 'Rappel paiement du loyer',
                    shortLibelle_Notification: 'Echéance prévue le jeudi 11 février',
                    // body_Notification: 'Pour rappel vous devez payer votre loyer avant le jeudi 11 février dernier délai.',
                    isPriority_Notification: true,
                    link_Notification: '',
                    status_Notification: 'envoyé',
                    startDate_Notification: new Date('2021-02-01T00:00:00'),
                    endDate_Notification: new Date('2021-02-12T00:00:00')
                },
                {
                    id_Notification_Locataires: 1,
                    id_Notification: 2,
                    code_tiers: '000580535',
                    syS_HASH: '454s8q4d4zq4d4a48dzsdqsd4zd5645',
                    syS_DATE_INSERTION: new Date('2021-02-02T00:00:00'),
                    syS_DATE_MAJ: new Date('2021-02-02T00:00:00'),
                    lue_Non_Lue: 1,
                    id_TypesNotification: 3,
                    title_Notification: 'Maintenance ascenseur',
                    shortLibelle_Notification: 'La maintenance est prévue le mardi 09 février ',
                    // body_Notification: 'L\'ascenseur sera inaccessible dans la journée du mardi 09 février pour la maintenance annuelle.',
                    isPriority_Notification: false,
                    link_Notification: '',
                    status_Notification: 'envoyé',
                    startDate_Notification: new Date('2021-03-01T00:00:00'),
                    endDate_Notification: new Date('2021-02-10T00:00:00')
                },
                {
                    id_Notification_Locataires: 1,
                    id_Notification: 3,
                    code_tiers: '000580535',
                    syS_HASH: '7dsad4s44s48z4qdz8qsqfe5sqzq4zq',
                    syS_DATE_INSERTION: new Date('2021-02-01T00:00:00'),
                    syS_DATE_MAJ: new Date('2021-02-01T00:00:00'),
                    lue_Non_Lue: 0,
                    id_TypesNotification: 3,
                    title_Notification: 'Canicules et forte chaleurs',
                    shortLibelle_Notification: 'Tout les ans ICF Habitat s\'associe au Plan national canicule du ministère de la Santé',
                    // body_Notification: 'En cas de fortes chaleurs, les personnes agées de plus de 65ans, les bébés ou les enfants de moins de 4ans sont les plus exposés aux risques de déshydratations ou d\'hyporthermie.',
                    isPriority_Notification: false,
                    link_Notification: '',
                    status_Notification: 'envoyé',
                    startDate_Notification: new Date('2021-02-01T00:00:00'),
                    endDate_Notification: new Date('2021-02-12T00:00:00')
                }
            ]
        }
    }
}
