<mat-dialog-content class="mat-typography">
    <h2 tabindex="0" class="text-primary text-center">{{rsc.title}}</h2>
    <!-- <div tabindex="0" class="font-16 m-b-10">{{rsc.textCookies}}</div> -->
    <div tabindex="0" class="font-16 m-b-10">{{rsc.textSession}}</div>
    <div tabindex="0" class="font-16 m-b-15">{{rsc.textLocal}}<button mat-flat-buttton
            class="text-primary clickable btn-link font-16 font-medium" (click)="openLink()">{{rsc.url}}</button>
    </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" class="m-t-10" fxLayoutGap="2%">
    <button mat-button class="btn-carbon cookies-btn-refuser"
        (click)="consentCookies(rsc.refuseValue)">{{rsc.btnRefuse}}</button>
    <button mat-raised-button class="btn-primary text-white btn-choice-cookie"
        (click)="consentCookies(rsc.acceptValue)">{{rsc.btnAccept}}</button>
</div>