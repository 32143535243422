import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthenticationComponent } from './authentication/components/authentication.component';
import { AdminGuard } from './core/guards/admin.guard';
import { MaintenanceGuard } from './core/guards/maintenance.guard';
import { AppLayoutComponent } from './layout/layout.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';
import { PresentationComponent } from './shared/components/presentation/presentation.component';
import { RefusCookiesComponent } from './shared/components/refus-cookies/refus-cookies.component';


export const AppRoutes: Routes = [
    {
        path: 'authentication',
        component: AuthenticationComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path: 'admin',
        component: AppLayoutComponent,
        canActivateChild: [MsalGuard, AdminGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule)
            }
        ]
    },
    {
        path: 'locataire',
        component: AppLayoutComponent,
        canActivateChild: [MsalGuard, MaintenanceGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./features/locataire/locataire.module').then(m => m.LocataireModule)
            }
        ]
    },
    {
        path: '',
        component: AuthenticationComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path: 'presentation',
        component: PresentationComponent
    },
    {
        path: 'error',
        component: ErrorComponent,
        data: { is404: false }
    },
    {
        path: '404',
        component: ErrorComponent,
        data: { is404: true }
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent
    },
    {
        path: 'refus-cookies',
        component: RefusCookiesComponent
    },
    {
        path: '**',
        redirectTo: '404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(AppRoutes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
