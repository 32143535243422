import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '../../core/services/error.service';
import { Maintenance } from '../../features/admin/parametrages/models/maintenance';
import { MajMaintenance } from '../../features/admin/parametrages/models/maj-maintenance';
import { MaintenanceLocataire } from '../models/maintenance-locataire';
import { Router } from '@angular/router';
import { DataSharedService } from './data-shared.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MaintenanceService {

    /**
     * Build an instance of MaintenanceService
     * @param {HttpClient} http 
     * @param {ErrorService} errorService 
     * @param {Router} router
     * @param {DataSharedService} dataSharedService
     */
    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private router: Router,
        private dataSharedService: DataSharedService
    ) {
    }

    /**
    * Récupérer les informations de maintenance par le locataire
    *
    * @returns {any}
    * @memberof MaintenanceService
    */
    getMaintenanceParamByLocataire(): Observable<MaintenanceLocataire> {
        return this.http
            .get(`${environment.apiUrl?.public}/GetMaintenanceParam`)
            .pipe(map((params: MaintenanceLocataire) => params),
                catchError(err => {
                    if (err && this.isError500(err)) {
                        this.router.navigate(['/maintenance']);
                        this.dataSharedService.isErrorMaintenance(true);
                        return throwError(err);
                    }
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
    * Récupérer les informations de maintenance par l'admin
    *
    * @returns {any}
    * @memberof MaintenanceService
    */
    getMaintenanceParamByAdmin(): Observable<Maintenance> {
        return this.http
            .get(`${environment.apiUrl?.protected}/AdminParam/GetMaintenanceParam`)
            .pipe(map((params: Maintenance) => params),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
    * Fait appel à l'api de mise à jour de maintenace par admin
    *
    * @memberof MaintenanceService
    */
    majMaintenance(maintenance: MajMaintenance): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/AdminParam/UpdateMaintenanceParam`, maintenance);
    }

    /**
     * Vérifie si l'erreur est une erreur 500
     * 
     * @param {HttpErrorResponse} HttpErrorResponse
     * 
     * @returns boolean
     */
    isError500(error: HttpErrorResponse): boolean {
        return error.error ? error.error.statusCode >= 400 : error.status >= 400;
    }
}
