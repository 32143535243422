export const _habilitations = {
    tableauDeBord: "TableauDeBord",
    infosPerso: "InfosPerso",
    infosPratiques: "InfosPratiques",
    liensUtiles: "LiensUtiles",
    paiement: "Paiement",
    compte: "Compte",
    documents: "Documents",
    assurance: "Assurance",
    compteur: "Compteur",
    demande: "Demande",
    utilisateurs: "Utilisateurs",
    statistiques: "Statistiques",
    notifications: "Notifications",
    modeles: "Modeles",
    logs: "Logs",
    parametrages: "Paramétrages",
}