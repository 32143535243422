<div fxLayout="column" fxLayoutAlign="center center">
    <div class="text-center">

        <img src="{{rsc.iconPath}}">

        <div class="refus-cookie-page">
            <p>{{ rsc.mainText }}</p>
            <p>{{ rsc.secondText }}</p>
            <p><a href="{{homeUrl}}">{{homeUrl}}</a></p>
        </div>

    </div>
</div>