<div fxLayout="column" class="container">
    <span class="title-presentation">{{ rsc.welcomeMessage }}</span>

    <vg-player id="videoPresentation">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>

        </vg-controls>

        <video [vgMedia]="media" #media preload="auto" crossorigin>
            <source src="{{ rsc.video.path }}" type="video/mp4">
        </video>
    </vg-player>

    <button class="btn-fixed-width btn-display btn-carbon m-t-10 m-b-10" mat-flat-button (click)="close()">
        <span class="white">{{ rsc.close.button}}</span>
    </button>
</div>