<div fxLayout="column" fxLayoutAlign="space-around center" class="accessibility-main-block">
    <h1 mat-dialog-title tabindex="0">{{ rsc.titleDarkTheme }}</h1>

    <mat-toolbar color="primary">
        <mat-toolbar-row class="accessibility-btn">
            <div tabindex="0">
                <mat-slide-toggle [checked]="isDarkTheme | async" labelPosition="before"
                    (change)="toggleDarktheme($event.checked)">{{ rsc.labelDarkTheme }}</mat-slide-toggle>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>