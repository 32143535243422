<div class="login-page">
    <div class="main-login" fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="space-between stretch"
        fxLayoutAlign.lt-md="start stretch">
        <div fxFlex.gt-sm="40%">
            <div [ngClass]="{'image-desktop': !isMobile.matches}">
                @if (isMobile.matches && imgLogo) {
                <div class="image-mobile">
                    <img [src]="imgLogo.path" class="logo-mobile" alt="{{ imgLogo.altText }}">
                </div>
                }

                @if (!isMobile.matches) {
                <img [src]="imgLogo.path" class="logo-desktop" alt="{{ imgLogo.altText }}">
                }

                @if (isMobile.matches) {
                <div class="title-mobile">
                    {{ login.titleL1 }} {{ login.titleL2 }}
                </div>
                }

                @if (!isMobile.matches) {
                <div class="title-desktop" fxLayout="column">
                    <div class="login-main-title">{{ login.titleL1 }}</div>
                    <div>{{ login.titleL2 }}</div>
                </div>
                }
            </div>
        </div>

        <div fxFlex.gt-sm="60%" fxLayout="column" fxLayoutAlign="space-around stretch">
            <!-- ERROR MESSAGE -->
            <div [ngClass]="{ 'login-form': !isMobile.matches, 'mobile-login-form': isMobile.matches }">
                @if (errorMessage) {
                <mat-card appearance="outlined" class="bg-danger">
                    <mat-card-content>
                        {{ errorMessage }}
                    </mat-card-content>
                </mat-card>
                }
            </div>
             <!-- TODO : Angular 17 à traiter -->
            <mat-card appearance="outlined" *ngIf="!isPageInformation; else page_information"
                [ngClass]="{ 'login-form': !isMobile.matches, 'mobile-login-form': isMobile.matches }">
                <mat-card-content>
                    <ng-content *ngIf="!isPageInformation" select="[authenticationForm]"></ng-content>
                </mat-card-content>
            </mat-card>
            <ng-template #page_information>
                <ng-content></ng-content>
            </ng-template>

            <div [ngClass]="{ 'm-t-30' : isMobile.matches, 'm-r-30': !isMobile.matches }">
                <div fxLayout="row" fxLayoutAlign.gt-sm="end" fxLayoutAlign.lt-md="center">
                    <div>
                        <a matLine (click)="openDialogUseTerms()" class="font-x-small footer-link mentionsLegales"
                            tabindex="0">{{ mentionsLegalesLabel }}</a>
                    </div>
                    <div>
                        <a matLine (click)="openDialogCgu()" class="font-x-small footer-link cgu" tabindex="0">{{
                            cguLabel }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>