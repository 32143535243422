import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataSharedService {
    private logoLoadedSource = new BehaviorSubject(false);
    private contratSelectedSource = new BehaviorSubject(false);
    private errorMaintenanceSource = new BehaviorSubject(false);

    public currentLogoLoaded = this.logoLoadedSource.asObservable();
    public currentContratSelected = this.contratSelectedSource.asObservable();
    public currentErrorMaintenance = this.errorMaintenanceSource.asObservable();

    constructor() {
        // Empty constructor
    }

    /**
     * Définit un boolean dès que le logo est chargé
     * 
     *  @param {boolean} isLoaded
     *  @memberof DataSharedService
     */
    isMobileLogoLoaded(isLoaded: boolean): void {
        this.logoLoadedSource.next(isLoaded);
    }

    /**
     * Définit un boolean dès qu'un contrat est sélectionné
     * 
     *  @param {boolean} isLoaded
     *  @memberof DataSharedService
     */
    isContratSelected(isSelected: boolean): void {
        this.contratSelectedSource.next(isSelected);
    }

    /**
     * Définit un boolean si une erreur lors de l'appel
     * de l'API maintenance
     * 
     * @param {Boolean} isError 
     * @memberof DataSharedService
     */
    isErrorMaintenance(isError: boolean): void {
        this.errorMaintenanceSource.next(isError);
    }

}
