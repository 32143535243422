import { Component, OnInit } from '@angular/core';
import { apiConfig } from '../../../core/b2c-config';
import { AppConfig } from '../../../core/services/app-config.service';

@Component({
  selector: 'app-refus-cookies',
  templateUrl: './refus-cookies.component.html',
  styleUrls: ['./refus-cookies.component.scss']
})
export class RefusCookiesComponent implements OnInit {
  rsc: any;
  homeUrl: string;

  /**
   * Build an instance of RefusCookiesComponent
   * 
   * @param {AppConfig} appConfig 
   * 
   * @memberof RefusCookiesComponent
   */
  constructor(
    private appConfig: AppConfig
  ) { }

  ngOnInit(): void {
    this.rsc = this.appConfig.rsc.pages.refusCookies;
    this.homeUrl = apiConfig.uri;
  }
}
