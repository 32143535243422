import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../../core/services/app-config.service';

@Component({
    selector: 'app-terms-use',
    templateUrl: './terms-use.component.html',
    styleUrls: ['./terms-use.component.scss']
})
export class TermsUseComponent implements OnInit {

    public rsc: any;
    public useTerms: any;

    constructor(
        private appConfig: AppConfig,
    ) { }

    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.pages.authentication;
        this.useTerms = this.rsc.useTerms;
    }
}
