import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-logo',
  templateUrl: './spinner-logo.component.html',
  styleUrls: ['./spinner-logo.component.scss']
})
export class SpinnerLogoComponent implements OnInit {

  @Input() isLoading: boolean;

  /***
   * Build an instance of SpinnerLogoComponent
   * 
   */
  constructor() {
    // Empty constructor
  }

  ngOnInit(): void {
    // Empty ngOnInit
  }

}
