import { environment } from '../../environments/environment';

/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: { scopes: string[]; uri: string } = {
    scopes: [environment.ICFB2CScope],
    uri: environment.redirectUri
};

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cAdminPolicies = {
    names: {
        signInSignUp: environment.ICFSignInUpADD
    },
    authorities: {
        signInSignUp: {
            authority: environment.ICFB2C + environment.ICFSignInUpADD,
        }
    },
    authorityDomain: environment.ICFauthorityDomain
};

export const b2cLocatairePolicies = {
    names: {
        signIn: environment.ICFSignIn,
        signUp: environment.ICFSignUp,
        forgotPassword: 'B2C_1_resetPasswordEspaceLocataire'
    },
    authorities: {
        signIn: {
            authority: environment.ICFB2C + environment.ICFSignIn
        },
        signUp: {
            authority: environment.ICFB2C + environment.ICFSignUp
        },
        forgotPassword: {
            authority: environment.ICFB2C + 'B2C_1_resetPasswordEspaceLocataire',
        }
    },
    authorityDomain: environment.ICFauthorityDomain
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */
export const msalConfig = {
    auth: {
        clientId: environment.ClientIDB2C, // This is the ONLY mandatory field; everything else is optional.
        authority: b2cLocatairePolicies.authorities.signIn.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [b2cLocatairePolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: environment.redirectUri + 'locataire/tableau-de-bord', // Defaults to "window.location.href".
        postLogoutRedirectUri: environment.redirectUri
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest = {
    admin: {
        authority: b2cAdminPolicies.authorities.signInSignUp.authority,
        scopes: apiConfig.scopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
        forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
    },
    locataire: {
        authority: b2cLocatairePolicies.authorities.signIn.authority,
        scopes: apiConfig.scopes, // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
        forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
    }
};

export const loginRequest = {
    admin: {
        authority: b2cAdminPolicies.authorities.signInSignUp.authority,
        scopes: apiConfig.scopes
    },
    locataire: {
        authority: b2cLocatairePolicies.authorities.signIn.authority,
        scopes: apiConfig.scopes
    }
};

export const logoutRequest = {
    admin: {
        authority: b2cAdminPolicies.authorities.signInSignUp.authority
    },
    locataire: {
        authority: b2cLocatairePolicies.authorities.signIn.authority
    }
};
