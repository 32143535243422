import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MixinService } from './mixin.service';

@Injectable()
export class AppConfig {
    private _rsc: any;
    private _userCtx: string;
    private _resources_path = './assets/i18n/';

    /**
     * Read resources
     *
     * @readonly
     * @type {*}
     * @memberof AppConfig
     */
    get rsc(): any {
        return this._rsc;
    }

    /**
     * Read user context
     *
     * @readonly
     * @type {string}
     * @memberof AppConfig
     */
    get userCtx(): string {
        return this._userCtx;
    }

    /**
     * Build an instance of AppConfig.
     * @param {MixinService} mixinService
     * @memberof AppConfig
     */
    constructor(private mixinService: MixinService) {
    }

    /**
     * Load app settings, resources and user context
     *
     * @param {TranslateService} translate
     * @returns
     * @memberof AppConfig
     */
    public load(translate: TranslateService) {
        return new Promise((resolve) => {
            this.mixinService.loadFile(`${this._resources_path}${this.setFichierTraductionByBrowserLanguage(translate)}.json`)
                .then((resource: any) => {
                    this._rsc = resource;
                    return resolve(true);
                });
        });
    }

    /**
     * Définit le fichier de traduction à utiliser
     * en fonction de la langue utilisée dans le navigateur
     * 
     * @param {TranslateService} translate 
     * @returns {String}
     * 
     * @memberof AppConfigComponent
     */
    setFichierTraductionByBrowserLanguage(translate: TranslateService): string {
        return translate.getBrowserLang() === 'fr' ? translate.getBrowserLang() : 'en';
    }
}
