import { Injectable } from '@angular/core';

/**
 * Application session storage service
 *
 * @export
 * @class StorageService
 */
@Injectable({
    providedIn: 'root',
})
export class StorageService {

    sessionStorage: Storage;
    localStorage: Storage;

    /**
     * Build an instance of StorageService.
     *
     * @memberOf StorageService
     */
    constructor() {
        this.sessionStorage = window.sessionStorage;
        this.localStorage = window.localStorage;
    }

    /**
     * Saves the item in session storage
     *
     * @param {string} key
     * @param value
     * @memberof StorageService
     */
    storeInSessionStorage(key: string, value: any) {
        if (value !== undefined && value !== null) {
            this.sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    /**
     * Gets the item value from session storage
     *
     * @param {string} key
     * @returns {string}
     * @memberof StorageService
     */
    getFromSessionStorage(key: string): any {
        return JSON.parse(this.sessionStorage.getItem(key));
    }

    /**
     * Removes the couple key/item value from session storage
     *
     * @param {string} key
     * @returns {string}
     * @memberof StorageService
     */
    removeFromSessionStorage(key: string) {
        this.sessionStorage.removeItem(key);
    }

    /**
     * Removes all items from session storage
     *
     * @memberof StorageService
     */
    clearSessionStorage(): void {
        this.sessionStorage.clear();
    }

    /**
     * Saves the item in local storage
     *
     * @param {string} key
     * @param value
     * @memberof StorageService
     */
    storeInLocalStorage(key: string, value: any) {
        if (value !== undefined && value !== null) {
            this.localStorage.setItem(key, JSON.stringify(value));
        }
    }

    /**
     * Gets the item value from local storage
     *
     * @param {string} key
     * @returns {string}
     * @memberof StorageService
     */
    getFromLocalStorage(key: string): any {
        return JSON.parse(this.localStorage.getItem(key));
    }

    /**
     * Removes the couple key/item value from local storage
     *
     * @param {string} key
     * @returns {string}
     * @memberof StorageService
     */
    removeFromLocalStorage(key: string) {
        this.localStorage.removeItem(key);
    }

    /**
     * Removes all items from local storage
     *
     * @memberof StorageService
     */
    clearLocalStorage(): void {
        this.localStorage.clear();
    }
}

