import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        public dialog: MatDialog,
    ) { }

    openDialog(component: any): void {
        this.dialog.open(component);
    }
}
