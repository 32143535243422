<!-- ============================================================== -->
<!-- Profile - style you can find in footer.scss -->
<!-- ============================================================== -->
<div class="ft-main" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center"
    fxLayoutAlign.lt-sm="space-around">
    <div fxLayout="column" fxLayoutAlign="space-between start" class="main-blocks">
        @if(sourceLogo) {
        <img [alt]="infosOrganismeLocataire.altText + ' bas de page'" class=" footer-logo" [src]="sourceLogo">
        }

        <div class="font-bold font-16"> {{ linkMessage }} </div>
        <div class="social-links">
            <a href="{{ socialLinks.twitter.url }}" rel="noopener noreferrer" target="_blank" title="Page tWitter">
                <i-feather class="network-icon" name="{{ socialLinks.twitter.icon }}"></i-feather>
            </a>
            <a href="{{ socialLinks.youtube.url }}" rel="noopener noreferrer" target="_blank" title="Chaine Youtube">
                <i-feather class="network-icon" name="{{ socialLinks.youtube.icon }}"></i-feather>
            </a>
            <a href="{{ socialLinks.linkedIn.url }}" rel="noopener noreferrer" target="_blank" title="Page linkedin">
                <i-feather class="network-icon" name="{{ socialLinks.linkedIn.icon }}"></i-feather>
            </a>
            <a href="{{ socialLinks.instagram.url }}" rel="noopener noreferrer" target="_blank"
                title="Compte Instagram">
                <i-feather class="network-icon" name="{{ socialLinks.instagram.icon }}"></i-feather>
            </a>
        </div>
    </div>
    @for ( footerItem of footerItems ; track footerItem) {
    <div fxLayout="column" fxLayoutAlign="space-between start" class="main-blocks">
        <div class="font-bold font-16"> {{ footerItem.name }} </div>
        @for ( child of footerItem.children ; track child) {
        <div class="font-x-small ft-row-item">
            <a tabindex="0" matLine (click)="OpenDialog( child )" rel=noopener
                [ngClass]="child.type === 'map-link' ? 'map-link' : 'link'">
                {{ child.name }}
            </a>
        </div>
        }
    </div>
    }
</div>