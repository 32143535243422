import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon-choice.component.html',
    styleUrls: ['./icon-choice.component.scss']
})
export class IconChoiceComponent {

    @Input() class: string;
    @Input() icon: string;
    @Input() iconType?: string;

    constructor(
    ) {
        // Empty constructor
    }
}
