@if (!isIframe && isSupportedBrowser) {
<router-outlet>
    <app-spinner></app-spinner>
</router-outlet>
}

@if (!isSupportedBrowser) {
<div fxLayout="row" fxLayoutAlign="center center">
    <p>{{label1Message}}{{currentBrowserVersion}}{{label2Message}}{{currentBrowser}}{{label3Message}}
    </p>
</div>
}