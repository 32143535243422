import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { b2cAdminPolicies, b2cLocatairePolicies, loginRequest } from './core/b2c-config';
import { AccessibilityService } from './core/services/accessibility.service';
import { AuthService } from './core/services/auth.service';
import { Constants } from './core/services/constants.service';
import { StorageService } from './core/services/storage.service';
import { MixinService } from './core/services/mixin.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { _browser } from '../app/shared/constants/browser';

interface IdTokenClaims extends AuthenticationResult {
    idTokenClaims: {
        tfp?: string
    };
}

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    themingSubscription: Subscription;
    @HostBinding('class') public cssClass: string;

    isIframe = false;
    private readonly _destroying$ = new Subject<void>();

    isSupportedBrowser: boolean;
    secureChromeVersion: number;
    secureFirefoxVersion: number;
    secureOperaVersion: number;
    secureEdgeVersion: number;
    secureSafariVersion: number;
    currentBrowser: string;
    currentBrowserVersion: string;

    label1Message: string;
    label2Message: string;
    label3Message: string;

    isUserHabilitationsChecked: boolean;

    /**
     * Creates an intance of AppComponent
     * 
     * @param {AccessibilityService} accessibilityService 
     * @param {OverlayContainer} overlayContainer 
     * @param {MsalService} msalService 
     * @param {MsalBroadcastService} msalBroadcastService 
     * @param {AuthService} authService 
     * @param {StorageService} storageService 
     * @param {Constants} constants
     * @param {ToastrService} notificationService 
     * @param {Router} router 
     * @param {MixinService} mixinService 
     * @param {DeviceDetectorService} deviceService 
     * 
     * @memberof AppComponent
     */
    constructor(
        private accessibilityService: AccessibilityService,
        private overlayContainer: OverlayContainer,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private authService: AuthService,
        private storageService: StorageService,
        private constants: Constants,
        private notificationService: ToastrService,
        private router: Router,
        private mixinService: MixinService,
        private deviceService: DeviceDetectorService
    ) {
        this.setLabelsMessage();
        this.allowApplicationAccessBySupportBrowser();
    }

    ngOnInit(): void {

        this.isIframe = window !== window.parent && !window.opener;
        this.themingSubscription = this.accessibilityService.theme.subscribe((theme: string) => {
            this.cssClass = theme;
            this.applyThemeOnOverlays();
        });
        this.msalConfig();
    }

    /**
     * Définit les ressources pour le message
     * d'alerte du navigateur non suppporté
     * 
     * @memberof AppComponent
     */
    setLabelsMessage(): void {
        this.label1Message = _browser.messageLabel1;
        this.label2Message = _browser.messageLabel2;
        this.label3Message = _browser.messageLabel3;
    }

    /**
     * Récupére les informations du navigateur
     * 
     * @memberof AppComponent
     */
    getInfoBrowser(): void {
        this.currentBrowser = this.deviceService.getDeviceInfo().browser;
        this.currentBrowserVersion = this.deviceService.getDeviceInfo().browser_version;
    }

    /**
     * Autorise l'accès à l'application en fonction
     * des informations du navigateur utilisé par l'utilsateur
     * 
     * 
     * @memberof AppComponent
     */
    allowApplicationAccessBySupportBrowser(): void {
        if (this.deviceService.getDeviceInfo()) {
            this.getInfoBrowser();
            this.setSecureVersionBrowser();

            switch (this.currentBrowser) {
                case _browser.chrome:
                    this.checkBrowserVersion(this.currentBrowserVersion, this.secureChromeVersion);
                    break;
                case _browser.firefox:
                    this.checkBrowserVersion(this.currentBrowserVersion, this.secureFirefoxVersion);
                    break;
                case _browser.opera:
                    this.checkBrowserVersion(this.currentBrowserVersion, this.secureOperaVersion);
                    break;
                case _browser.safari:
                    this.checkBrowserVersion(this.currentBrowserVersion, this.secureSafariVersion);
                    break;
                case _browser.edge:
                    this.checkBrowserVersion(this.currentBrowserVersion, this.secureEdgeVersion);
                    break;
                case _browser.IE:
                    this.isSupportedBrowser = false;
                    break;
                default:
                    this.isSupportedBrowser = true;
            }
        }
    }

    /**
     * Vérifie la version du navigateurs utilisée
     * vs la version supportée
     * 
     * @param {String} currentBrowserVersion 
     * @param {Number} secureVersionBrowser 
     * 
     * @memberof AppComponent
     */
    checkBrowserVersion(currentBrowserVersion: string, secureVersionBrowser: number): void {
        const version = currentBrowserVersion.split('.');
        const currentVersion = parseInt(version[0]);

        if (currentVersion >= secureVersionBrowser) {
            this.isSupportedBrowser = true;
        } else {
            this.isSupportedBrowser = false;
        }
    }

    /**
     * Définit les versions supportées
     * pour chaque navigateur
     * 
     * @memberof AppComponent
     */
    setSecureVersionBrowser(): void {
        this.secureChromeVersion = _browser.versionSupportChrome;
        this.secureFirefoxVersion = _browser.versionSupportFirefox;
        this.secureOperaVersion = _browser.versionSupportOpera;
        this.secureEdgeVersion = _browser.versionSupportEdge;
        this.secureSafariVersion = _browser.versionSupportSafari;
    }

    private applyThemeOnOverlays() {
        // remove old theme class and add new theme class
        // we're removing any css class that contains '-theme' string but your theme classes can follow any pattern
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
        const themeClassesToRemove = Array.from(this.accessibilityService.themes);
        if (themeClassesToRemove.length) {
            overlayContainerClasses.remove(...themeClassesToRemove);
        }
        overlayContainerClasses.add(this.cssClass);
    }

    /**
     * Récupération des informations de connexion MSAL
     * Appel nécessaire au démarrage de l'appli car avec le login redirect B2C, il n'y a pas de callback possible
     * comme c'est le cas avec le login popup
     *
     * On souscrit ici aux deux types de retour possibles, success ou error, pour le login et la demande de token
     *
     * @memberof AppComponent
     */
    private msalConfig(): void {
        // Souscription à la récupération des infos de connexion MSAL lorsque le redirect est utilisé
        this.msalService.handleRedirectObservable().subscribe({
            next: (result: AuthenticationResult) => {
                // Connexion => récupération des informations du compte
                // En cas de refresh de la page ou de navigation il est possible que result soit null, ainsi que
                // msalService.instance.getActiveAccount(), on le réactive avec celui du storage dans ce cas
                const account = result?.account || this.storageService.getFromSessionStorage(this.constants.APP_AUTH)?.account;
                if (account) {
                    this.msalService.instance.setActiveAccount(account);
                }
            },
            error: (error) => {
                this.router.navigate(['authentication', 'login']);
                this.mixinService.refresh();
                if (error.toString().indexOf(this.constants.B2C_ERROR_CANCEL_ENTRY_CODE) === -1) {
                    this.notificationService.error(error);
                }
            }
        });

        // LOGIN SUCCESS
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                    || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                const payload: IdTokenClaims = <AuthenticationResult>result.payload;

                // We need to reject id tokens that were not issued with the default sign-in policy.
                // "tfp" claim in the token tells us what policy is used
                // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                if (payload.idTokenClaims?.tfp === b2cLocatairePolicies.names.forgotPassword) {
                    this.notificationService.success('Password has been reset successfully. \nPlease sign-in with your new password.');
                    return this.authService.logout();
                }

                // Récupère les infos de connexion et token
                // Locataire connexion
                if (payload.idTokenClaims?.tfp === b2cLocatairePolicies.names.signIn) {
                    if (!this.storageService.getFromSessionStorage(this.constants.LOGGED_IN)) {
                        this.authService.connectLocataire(payload);
                    } else if (this.router.url.indexOf('authentication') !== -1) {
                        this.router.navigate(['/locataire']);
                    }
                }
                // Locataire inscription
                else if (payload.idTokenClaims?.tfp === b2cLocatairePolicies.names.signUp) {
                    if (this.router.url.indexOf('authentication') !== -1) {
                        this.storageService.clearSessionStorage();
                        this.authService.login(loginRequest.locataire);
                        this.router.navigate(['/locataire']);
                    }
                }
                // Admin
                else if (payload.idTokenClaims?.tfp === b2cAdminPolicies.names.signInSignUp) {
                    if (!this.storageService.getFromSessionStorage(this.constants.LOGGED_IN)) {
                        this.authService.connectAdmin(payload);
                    } else if (this.router.url.indexOf('authentication') !== -1) {
                        this.router.navigate(['/admin']);
                    }
                }

                const userHabilitations = this.storageService.getFromSessionStorage(this.constants.HABILITATIONS);
                if (!userHabilitations) {
                    this.setUserHabilitations();
                } else {
                    this.isUserHabilitationsChecked = true;
                }

                return result;
            });

        // LOUGOUT
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS
                    || msg.eventType === EventType.LOGOUT_FAILURE),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.router.navigate(['authentication', 'login']);
                this.storageService.clearSessionStorage();
            });
    }

    /***
    * Récupére et définit les habilitations de l'utilisateur
    * connecté
    * 
    * @memberof AppComponent
    */
    setUserHabilitations(): void {
        if (!this.authService.isAdmin() && !this.authService.isLoggedAs()) {
            this.authService.getUserHabilitations().subscribe(habilitations => {
                if (habilitations) {
                    this.storageService.storeInSessionStorage(this.constants.HABILITATIONS, habilitations);
                    this.isUserHabilitationsChecked = true;
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.themingSubscription.unsubscribe();
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
