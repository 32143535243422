<div class="error-page" fxLayout="column" fxLayoutAlign="center center">

    <div class="text-center">
        <h1 tabindex="0">{{ rsc.title }}</h1>

        <div fxLayout="column" fxLayoutGap="40px">
            <h2 tabindex="0">{{ rsc.subtitle }}</h2>

            <div fxLayout="column" tabindex="0">
                <p>{{ rsc.mainText }}</p>
                <p>{{ isUserConnected ? rsc.secondText : rsc.secondTextBis }}</p>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="accent" (click)="goToHomePage()">
                    <span class="white font-small" tabindex="0">
                        {{ isUserConnected ? buttons.goBackHome : buttons.goBackLogin }}
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </span>
                </button>
            </div>

        </div>
    </div>

</div>
