<!-- LOADING SPINNER AND MESSAGE -->
@if (isLoading) {
<ng-container>
    @if (noCard && isLoading) {
    <div class="spinner-error-centered-text">
        <i [ngClass]="getSpinnerSize()" class="fa fa-spinner fa-pulse"></i>
    </div>
    }

    @if (!noCard && isLoading) {
    <mat-card appearance="outlined" class="spinner-error-centered-text">
        @if (getLoadingMessage()) {
        <div class="spinner-error-title-card">
            {{ getLoadingMessage() }}
        </div>
        }

        <mat-card-content>
            <i [ngClass]="[getSpinnerSize(), getSpinnerColor()]" class="fa fa-spinner fa-pulse"></i>
        </mat-card-content>
    </mat-card>
    }

</ng-container>
}


<!-- ERROR MESSAGE -->
@if (isError) {
<ng-container>
    <app-card-icon [centerIcon]="true" [iconColor]="'danger'" [iconType]="'font-awesome'" [icon]="'fa fa-exclamation'"
        [message]="getErrorMessage()" [title]="errorTitle">
    </app-card-icon>
</ng-container>
}