import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppConfig } from '../../core/services/app-config.service';
import { AuthService } from '../../core/services/auth.service';
import { Constants } from '../../core/services/constants.service';
import { CguComponent } from '../../shared/components/content/cgu/cgu.component';
import { TermsUseComponent } from '../../shared/components/content/terms-use/terms-use.component';
import { IMenu } from '../../shared/models/menu';
import { DialogService } from '../../shared/services/dialog.service';
import { CookiesBottomSheetComponent } from './components/cookies-bottom-sheet/cookies-bottom-sheet.component';
import { PromptComponent } from '../../shared/components/prompt/prompt.component';
import { _configOrganismes } from '../../accessibility/config/organismes-locataire';
import { infosOrganismeLocataire } from '../../shared/models/infosOrganismeLocataires';
import { DataSharedService } from '../../shared/services/data-shared.service';
import { MixinService } from '../../core/services/mixin.service';
import { StorageService } from '../../core/services/storage.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
})
export class AppFooterComponent implements OnInit {
    public rsc: any;
    public titre: string;
    public linkMessage: string;
    public footerItems: IMenu[];
    public socialLinks: any;
    public sheetCookie: any;

    sourceLogo: string;

    eventsSubscription: Subscription;

    @Input() onLogoLoaded: Observable<boolean>;

    infosOrganismeLocataire: infosOrganismeLocataire;
    onSelectedContrat: Subscription;

    /**
     * Build an instance of AppFooterComponent.
     * 
     * @param {AppConfig} appConfig
     * @param {DialogService} dialogService
     * @param {MatBottomSheet} bottomSheet
     * @param {MatBottomSheet} sheetValidationCookies
     * @param {AuthService} authService
     * @param {Constants} constants
     * @param {Platform} platform
     * @param {DataSharedService} dataSharedService
     * @param {MixinService} mixinService
     * @param {StorageService} storageService
     * @param {Router} router
     * 
     * @memberof AppFooterComponent
     */
    constructor(
        private appConfig: AppConfig,
        private dialogService: DialogService,
        private sheetValidationCookies: MatBottomSheet,
        private bottomSheet: MatBottomSheet,
        private authService: AuthService,
        private constants: Constants,
        private platform: Platform,
        private dataSharedService: DataSharedService,
        private mixinService: MixinService,
        private storageService: StorageService,
        private router: Router
    ) {
        this.checkNavigationCookies();
    }

    ngOnInit() {
        this.setLogoBySelectedContrat();
        this.rsc = this.appConfig.rsc.layout.footer;
        this.titre = this.rsc.footerTitre;
        this.linkMessage = this.rsc.linkMessage;
        this.footerItems = this.rsc.footerItems;
        this.socialLinks = this.rsc.socialLinks;
        this.eventsSubscription = this.onLogoLoaded.subscribe((isLoaded) => {
            if (isLoaded) {
                this.findInfosOrganismeLocataire();

                if (this.infosOrganismeLocataire) {
                    this.sourceLogo = this.infosOrganismeLocataire.path + this.infosOrganismeLocataire.logo;
                }
            }
        });
    }

    /**
     * Définit le logo en fonction du contrat sélectionné
     * 
     * @memberof FooterComponent
     */
    setLogoBySelectedContrat(): void {
        this.onSelectedContrat = this.dataSharedService.currentContratSelected.subscribe((isSelected) => {
            if (isSelected) {
                this.findInfosOrganismeLocataire();

                if (this.infosOrganismeLocataire) {
                    this.sourceLogo = this.infosOrganismeLocataire.path + this.infosOrganismeLocataire.logo;
                }
            }
        })
    }

    /**
     * Récupére l'objet InfosOrganismeLocataire en fonction du code organisme
     * récupéré du session storage
     * 
     * @memberof FooterComponent
     */
    findInfosOrganismeLocataire(): void {
        const codeOrganisme = this.mixinService.getCodeOrganismeLocataire();
        this.infosOrganismeLocataire = _configOrganismes.find(infosOrganisme => infosOrganisme.code === codeOrganisme);
    }


    /**
     * Ouvre un composant mentions legales, cgu, ou charte de gestion de cookies selon les cas
     * 
     * @memberof AppFooterComponent
     */
    OpenDialog(child: any) {
        switch (child.label) {
            case 'mentions-legales':
                this.dialogService.openDialog(TermsUseComponent);
                break;
            case 'cgu':
                this.dialogService.openDialog(CguComponent);
                break;
            case 'charte-gestion-cookies':
                this.openBottomSheetCookies();
                break;
            default:
                window.open(child.url, '_blank');
                break;
        }
    }

    /**
     * De la connexion ouvre le botttom sheet pour consentement d'utilisatuer sur cookies
     * - S'ouvre si l'utilsateur n'a jamais accepté le cookies et si pendant la derniere session les cookies était refusés
     * - Ne s'ouvre pas pour admin ou si pas connecté
     * 
     * @memberof AppFooterComponent
     */
    checkNavigationCookies() {
        const isConsented = this.storageService.getFromLocalStorage(this.constants.COOKIE_NAV);

        if ((this.storageService.getFromLocalStorage(this.constants.COOKIE_NAV) === this.constants.REFUSE_COOKIE_NAV || !isConsented) && !this.isAdmin() && this.authService.isLoggedIn()) {
            this.openBottomSheetCookies();
        }
    }
    /**
     * Gestion de l'ouverture de fenetre cookies, selon le choix d'utlisateur
     * 
     * 
     * @memberof AppFooterComponent
     */
    openBottomSheetCookies() {
        this.sheetCookie = this.sheetValidationCookies.open(CookiesBottomSheetComponent, {
            closeOnNavigation: false,
            disableClose: true,
            hasBackdrop: false,
        });
        this.sheetCookie.afterDismissed().subscribe((acceptedCookies: string) => {
            if (acceptedCookies === this.constants.REFUSE_COOKIE_NAV) {
                this.storageService.clearSessionStorage();
                this.router.navigate([this.constants.URL_REFUS_COOKIES]);
            }
            if (this.platform.IOS) {
                const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
                if (!isInStandaloneMode) {
                    this.openPromptComponent();
                }
            }
        });
    }

    /**
     * Gestion de l'ouverture de fenetre PWA IOS
     * 
     * 
     * @memberof AppFooterComponent
     */
    openPromptComponent(): void {
        timer(1)
            .pipe(take(1))
            .subscribe(() => {
                this.bottomSheet.open(PromptComponent, {
                    closeOnNavigation: false,
                    disableClose: true,
                    hasBackdrop: false,
                });
            });
    }

    /**
     * Vérifie si l'utilisateur est un admin
     *
     * @returns boolean
     * @memberof AppFooterComponent
     */
    isAdmin(): boolean {
        return this.authService.isAdmin();
    }
}
