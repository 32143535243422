import { ApplicationRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Constants } from './constants.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class AccessibilityService {
    themes = [this.constants.DARK_THEME, this.constants.LIGHT_THEME];
    theme = new BehaviorSubject(this.constants.LIGHT_THEME);
    isDarkOn: boolean;

    /**
     * Build an instance of AccessibilityService.
     * @param {Constants} constants
     * @param {StorageService} storageService
     * @param {ApplicationRef} ref
     * @memberof AccessibilityService
     */
    constructor(
        private constants: Constants,
        private storageService: StorageService,
        private ref: ApplicationRef
    ) {
        this.initTheme();
    }

    /**
    * Initialize the color mode and subscribes to eventuel changes
    *
    * @memberof AccessibilityService
    */
    initTheme(): void {
        const toggleDarkInStorage = this.storageService.getFromLocalStorage(this.constants.DARK_THEME);

        if (toggleDarkInStorage === null) {
            this.isDarkOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        } else {
            this.isDarkOn = toggleDarkInStorage;
        }

        /**
         * initially trigger :
         * - dark mode if preference is set to dark mode on system and the accessibility toggle button has not been touched
         * - the mode of the accessibility toggle button if it has been touched
         */
        this.theme.next(this.constants.MAP_THEME.get(this.isDarkOn));

        // watch for changes of the preference
        window.matchMedia('(prefers-color-scheme: dark)').addListener(e => {
            this.isDarkOn = e.matches;
            this.theme.next(this.isDarkOn ? this.constants.DARK_THEME : this.constants.LIGHT_THEME);
            // trigger refresh of UI
            this.ref.tick();
        });
    }

    /**
    * Storage of color theme
    *
    * @param {*} isDarkThemeSelected
    * @memberof AccessibilityService
    */
    storeDarkTheme(isDarkThemeSelected: boolean) {
        this.isDarkOn = isDarkThemeSelected;
        this.storageService.storeInLocalStorage(this.constants.DARK_THEME, isDarkThemeSelected);
    }
}
