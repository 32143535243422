import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-icon',
    templateUrl: './card-icon.component.html',
    styleUrls: ['./card-icon.component.scss']
})
export class CardIconComponent {

    @Input() title: string;
    @Input() icon: string;
    @Input() iconType?: string;
    @Input() message: string;
    @Input() path: string;
    @Input() centerIcon?: boolean;
    @Input() centerText?: boolean;
    @Input() iconColor?: string;

    constructor(
    ) {
        // Empty constructor
    }

    /**
     * Retourne la couleur de l'icone en fonction de l'input iconColor
     * Si aucune couleur n'est donnée en entrée retourne light par défaut
     *
     * @memberof CardIconComponent
     */
    getIconColor(): string {
        return this.iconColor ? this.iconColor + '-icon' : 'light-icon';
    }


    /**
     * Retourne le margin de l'icone en fonction de l'input iconType
     * Si aucune iconType n'est donnée en entrée retourne mat-icon-margin par défaut
     *
     * @memberof IconChoiceComponent
     */
    getIconClass(positionClass: string): string {
        const iconClass = this.iconType ? this.iconType + '-margin ' : 'mat-icon-margin ';
        return iconClass.concat(' ', positionClass);
    }
}
