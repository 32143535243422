import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AccessibilityService } from '../../core/services/accessibility.service';
import { AppConfig } from '../../core/services/app-config.service';
import { Constants } from '../../core/services/constants.service';

@Component({
    selector: 'app-accessibility',
    templateUrl: './accessibility.component.html',
    styleUrls: ['./accessibility.component.scss']
})
export class AccessibilityComponent implements OnInit {
    rsc: any;
    themes: string[];

    /**
     * Build an instance of AccessibilityComponent.
     * 
     * @param {AppConfig} appConfig
     * @param {AccessibilityService} accessibilityService
     * @param {Constants} constants
     * 
     * @memberof AccessibilityComponent
     */
    constructor(
        private appConfig: AppConfig,
        private accessibilityService: AccessibilityService,
        private constants: Constants,
    ) {
    }

    /**
     * Gets the current color mode.
     *
     * @returns {Observable<boolean>}
     * @memberof AccessibilityComponent
     */
    get isDarkTheme(): Observable<boolean> {
        return of(this.accessibilityService.isDarkOn);
    }

    /**
     * Initializes AccessibilityComponent
     *
     * @memberof AccessibilityComponent
     */
    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.pages.accessibility;
        this.themes = this.accessibilityService.themes;
    }

    /**
     * Changes the color mode if toggled
     *
     * @param {boolean} checked
     * @memberof AccessibilityComponent
     */
    toggleDarktheme(checked: boolean): void {
        this.accessibilityService.storeDarkTheme(checked);
        this.accessibilityService.theme.next(this.constants.MAP_THEME.get(checked));
    }

}
