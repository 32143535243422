<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<!-- TODO : Angular 17 à traiter -->
<div class="main-container" *ngIf="isFirstConnection === false">
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Bandeau : connexion en tant que -->
    <!-- ============================================================== -->
    @if (isLoggedAs()) {
    <div class="layout-bandeau-admin" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="layout-bandeau-message" fxFlex="45" fxFlex.gt-md="60" fxFlex.md="60">{{rscAdmin.loggedAs.bandeau}}
            {{userEmail}}</span>
        <button fxFlex="45" fxFlex.gt-md="25" fxFlex.md="30" class="layout-bouton-quitter-mode" mat-raised-button
            (click)="onExit()">{{rscAdmin.loggedAs.button}}</button>
    </div>
    }

    <mat-toolbar fxFlex="row" fxLayoutAlign="space-between center">
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->
        <div class="layout-logo">
            <button [attr.aria-label]="rsc.header.menu.aria" mat-icon-button (click)="snav.toggle()"
                value="sidebarclosed">
                <mat-icon>{{ rsc.header.menu.icon }}</mat-icon>
            </button>
            <app-spinner-logo [isLoading]="isLogoLoading && !mobileQuery.matches && !isLoadedContrat" fxLayout="row"
                fxLayoutAlign="center end">
            </app-spinner-logo>
            @if((!mobileQuery.matches && sourceLogo && isLoadedContrat) || (isAdmin && sourceLogo &&
            !mobileQuery.matches)) {
            <img [alt]="infosOrganismeLocataire?.altText" [matTooltip]="appVersion" [src]="sourceLogo"
                (click)="onClick()" class="layout-logo-icf">
            }

        </div>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header [style.margin-right.px]="setMarginTitleHeader()"></app-header>
        <!-- ============================================================== -->
        <!-- Notification -->
        <!-- ============================================================== -->
        @if ((!isAdmin() || isLoggedAs()) && numeroTiers) {
        <app-notifications [hidden]="!hasContratsActifs" [numeroTiers]="numeroTiers">
        </app-notifications>
        }

        @if (!isAdmin() || isLoggedAs()) {
        <div [hidden]="hasContratsActifs"></div>
        }

        @if (isAdmin()) {
        <div>
            <!-- ============================================================== -->
            <!-- div vide : emplacement disponible si besoin-->
            <!-- ============================================================== -->
        </div>
        }

    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container (backdropClick)="snav.close()" [ngClass]="{'minisidebar': minisidebar}">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" [mode]="'over'" fixedInViewport="true" fixedTopGap="0"
            (keydown.escape)="snav.close()" [opened]="false" [disableClose]="!mobileQuery.matches"
            (close)="sidebarOpened = false" [perfectScrollbar]="config">
            <mat-toolbar>
                <mat-toolbar-row>
                    <button [attr.aria-label]="rsc.header.closeMenu.aria" mat-icon-button (click)="snav.toggle()"
                        value="sidebarclosed">
                        <mat-icon>{{ rsc.header.closeMenu.icon }}</mat-icon>
                    </button>
                    @if (sourceLogo) {
                    <img alt="LogoICF" [matTooltip]="appVersion" class="layout-logo-icf" [src]="sourceLogo">
                    }
                </mat-toolbar-row>
            </mat-toolbar>
            <app-sidebar (onClickOnMenuItem)="snav.close()" class="app-sidebar" [userNameLocataire]="userName">
            </app-sidebar>
        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div>
                <router-outlet></router-outlet>
                <div toastr-container></div>
            </div>
            <app-spinner-logo [isLoading]="isLogoLoading" fxLayout="row" fxLayoutAlign="center end">
            </app-spinner-logo>
            @if (!isAdmin()) {
            <app-footer [onLogoLoaded]="onLoadedLogo.asObservable()"></app-footer>
            }

        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>