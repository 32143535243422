import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { MaterialModule } from '../material.module';
import { AuthenticationLayoutComponent } from './components/authentication/layout/authentication-layout.component';
import { CardIconComponent } from './components/card-icon/card-icon.component';
import { CguComponent } from './components/content/cgu/cgu.component';
import { TermsUseComponent } from './components/content/terms-use/terms-use.component';
import { ContratsComponent } from './components/contrats/contrats.component';
import { ErrorComponent } from './components/error/error.component';
import { SmallCardWarningComponent } from './components/small-card-warning/small-card-warning.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FieldErrorsComponent } from './components/validation/field/field-errors.component';
import { FormErrorsComponent } from './components/validation/form/form-errors.component';
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './directives/accordion';
import { SyntheseSoldeComponent } from './components/synthese-solde/synthese-solde.component';
import { IconChoiceComponent } from './components/icon-choice/icon-choice.component';
import { SpinnerAndErrorComponent } from './components/spinner-and-error/spinner-and-error.component';
import { UnProblemeComponent } from './components/un-probleme/un-probleme.component';
import { FrenchPhonePipe } from './pipes/french-phone-number.pipe';
import { IbanPipe } from './pipes/iban.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { BlackListPipe } from './pipes/black-list.pipe';
import { DateYearPipe } from './pipes/date-year.pipe';
import { DateFrenchFormat } from './pipes/date-french-format.pipe';
import { DateHoursFrenchFormat } from './pipes/date-hours-french-format.pipe';
import { DateMonthPipe } from './pipes/date-month.pipe';
import { CurrencyEuroPipe } from './pipes/currency-euro.pipe';
import { LabelComponent } from './components/label/label.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { InfosExpansionPanelComponent } from './components/infos-expansion-panel/infos-expansion-panel.component';
import { SpinnerLogoComponent } from './components/spinner-logo/spinner-logo.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { FilArianeComponent } from './components/fil-ariane/fil-ariane.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        FlexLayoutModule,
        FeatherModule.pick(allIcons),
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule
    ],
    declarations: [
        ErrorComponent,
        SpinnerComponent,
        FieldErrorsComponent,
        FormErrorsComponent,
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        SmallCardWarningComponent,
        AuthenticationLayoutComponent,
        TermsUseComponent,
        CguComponent,
        CardIconComponent,
        IconChoiceComponent,
        ContratsComponent,
        SyntheseSoldeComponent,
        SpinnerAndErrorComponent,
        UnProblemeComponent,
        FrenchPhonePipe,
        IbanPipe,
        SafeHtmlPipe,
        BlackListPipe,
        DateYearPipe,
        DateFrenchFormat,
        DateHoursFrenchFormat,
        DateMonthPipe,
        CurrencyEuroPipe,
        LabelComponent,
        MaintenanceComponent,
        PresentationComponent,
        InfosExpansionPanelComponent,
        SpinnerLogoComponent,
        FilArianeComponent
    ],
    exports: [
        ErrorComponent,
        SpinnerComponent,
        FieldErrorsComponent,
        FormErrorsComponent,
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        SmallCardWarningComponent,
        AuthenticationLayoutComponent,
        CardIconComponent,
        IconChoiceComponent,
        ContratsComponent,
        SyntheseSoldeComponent,
        SpinnerAndErrorComponent,
        UnProblemeComponent,
        FrenchPhonePipe,
        IbanPipe,
        SafeHtmlPipe,
        BlackListPipe,
        DateYearPipe,
        DateFrenchFormat,
        DateHoursFrenchFormat,
        DateMonthPipe,
        CurrencyEuroPipe,
        LabelComponent,
        MaintenanceComponent,
        PresentationComponent,
        InfosExpansionPanelComponent,
        SpinnerLogoComponent,
        FilArianeComponent
    ],
    providers: [
        DecimalPipe
    ]
})
export class SharedModule {
}
