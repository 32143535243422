import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxIbanModule } from 'ngx-iban';
import { AccessibilityModule } from './accessibility/accessibility.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ConnectAsLocataireModule } from './features/admin/connect-as-locataire/connect-as-locataire.module';
import { ParametresModule } from './features/locataire/parametres/parametres.module';
import { CookiesBottomSheetComponent } from './layout/footer/components/cookies-bottom-sheet/cookies-bottom-sheet.component';
import { AppFooterComponent } from './layout/footer/footer.component';
import { AppHeaderComponent } from './layout/header/header.component';
import { AppLayoutComponent } from './layout/layout.component';
import { AppNotificationsComponent } from './layout/notifications/notifications.component';
import { AppSidebarComponent } from './layout/sidebar/sidebar.component';
import { MaterialModule } from './material.module';
import { PromptComponent } from './shared/components/prompt/prompt.component';
import { SharedModule } from './shared/shared.module';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';


registerLocaleData(localeFr);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent,
        AppLayoutComponent,
        AppHeaderComponent,
        AppSidebarComponent,
        AppNotificationsComponent,
        AppFooterComponent,
        CookiesBottomSheetComponent,
        PromptComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        MaterialModule,
        FlexLayoutModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        NgMultiSelectDropDownModule.forRoot(),
        FeatherModule.pick(allIcons),
        AccessibilityModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerImmediately' }),
        ParametresModule,
        ConnectAsLocataireModule,
        NgxIbanModule
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'EUR'
        },
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
