@if (!iconType || iconType === 'mat-icon') {
<mat-icon [ngClass]="class">{{ icon }}</mat-icon>
}

@if (iconType === 'i-feather') {
<i-feather [ngClass]="class" name="{{ icon }}"></i-feather>
}

@if (iconType === 'font-awesome') {
<i [ngClass]="[class, icon]"></i>
}