import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../../../shared/shared.module';

// Components
import { ConnectAsLocataireComponent } from './components/connect-as-locataire.component';
import { FeatherModule } from 'angular-feather';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FlexLayoutModule,
        SharedModule,
        FeatherModule
    ],
    exports: [
        ConnectAsLocataireComponent
    ],
    declarations: [
        ConnectAsLocataireComponent
    ]
})
export class ConnectAsLocataireModule {
}
