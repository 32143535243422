import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfig } from '../../../core/services/app-config.service';

@Component({
    selector: 'app-prompt',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {
    rsc: any;

    constructor(
        private appConfig: AppConfig,
        private bottomSheetRef: MatBottomSheetRef<PromptComponent>
    ) { }

    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.shared.pwa;
    }

    close(): void {
        this.bottomSheetRef.dismiss();
    }
}
