import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { AccessibilityComponent } from './components/accessibility.component';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [
        AccessibilityComponent
    ]
})
export class AccessibilityModule {
}
