import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppConfig } from '../../../../core/services/app-config.service';
import { MobileService } from '../../../../core/services/mobile.service';
import { TermsUseComponent } from '../../content/terms-use/terms-use.component';
import { CguComponent } from '../../content/cgu/cgu.component';
import { DialogService } from '../../../services/dialog.service';
import { Router } from '@angular/router';
import { StorageService } from '../../../../core/services/storage.service';
import { Constants } from '../../../../core/services/constants.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CookiesBottomSheetComponent } from '../../../../layout/footer/components/cookies-bottom-sheet/cookies-bottom-sheet.component';

@Component({
    selector: 'app-authentication-layout',
    templateUrl: './authentication-layout.component.html',
    styleUrls: ['./authentication-layout.component.scss']
})
export class AuthenticationLayoutComponent implements OnInit, OnDestroy {
    @Input() errorMessage: string;

    isMobile: MediaQueryList;
    rsc: any;
    cguLabel: string;
    mentionsLegalesLabel: string;
    currentUrl: string;
    isPageInformation: boolean;

    public sheetCookie: any;
    public login: any;
    imgLogo: {
        path: string,
        altText: string
    };

    /**
     * Build an instance of AuthenticationLayout.
     * @param changeDetectorRef 
     * 
     * @param {AppConfig} appConfig 
     * @param {DialogService} dialogService 
     * @param {MatBottomSheet} sheetValidationCookies
     * @param {MobileService} mobileService 
     * @param {Router} router
     * @param {StorageService} storageService 
     * @param {Constants} constants
     */
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private appConfig: AppConfig,
        private sheetValidationCookies: MatBottomSheet,
        private dialogService: DialogService,
        private mobileService: MobileService,
        private router: Router,
        private storageService: StorageService,
        private constants: Constants
    ) {
        this.isMobile = this.mobileService.mobileQueryListener(this.changeDetectorRef, '960');
        this.currentUrl = this.router.url;

        if (this.questionInformation()) {
            this.isPageInformation = true;
        }

        this.checkNavigationCookies();
    }

    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.pages.authentication;
        this.login = this.rsc.login;

        this.setImagePath();
        this.cguLabel = this.rsc.useTerms.linksTerms.cguLabel;
        this.mentionsLegalesLabel = this.rsc.useTerms.linksTerms.mentionsLegalesLabel;
    }

    openDialogUseTerms(): void {
        this.dialogService.openDialog(TermsUseComponent);
    }

    openDialogCgu(): void {
        this.dialogService.openDialog(CguComponent);
    }

    setImagePath(): void {
        const imgLogoRsc = this.appConfig.rsc.layout.images.logo;
        this.imgLogo = {
            path: imgLogoRsc.path,
            altText: imgLogoRsc.altText
        };
    }

    /**
     * Définit la condition suivante : si l'utilisateur se trouve dans la page information
     * 
     * @memberof AuthenticationLayoutComponent
     * @returns boolean
     */
    questionInformation(): boolean {
        return this.currentUrl === '/question-information';
    }

    /**
     * De la connexion ouvre le botttom sheet pour consentement d'utilisatuer sur cookies
     * - S'ouvre si l'utilsateur n'a jamais accepté le cookies et si pendant la derniere session les cookies était refusés
     * - Ne s'ouvre pas pour admin ou si pas connecté
     * 
     * @memberof AuthenticationLayoutComponent
     */
    checkNavigationCookies() {
        const isConsented = this.storageService.getFromLocalStorage(this.constants.COOKIE_NAV);
        if (this.storageService.getFromLocalStorage(this.constants.COOKIE_NAV) === this.constants.REFUSE_COOKIE_NAV || !isConsented) {
            this.openBottomSheetCookies();
        }
    }

    /**
     * Gestion de l'ouverture de fenetre cookies, selon le choix d'utlisateur
     * @memberof AuthenticationLayoutComponent
     */
    openBottomSheetCookies() {
        this.sheetCookie = this.sheetValidationCookies.open(CookiesBottomSheetComponent, {
            closeOnNavigation: false,
            disableClose: true,
            hasBackdrop: false,
        });
        this.sheetCookie.afterDismissed().subscribe((acceptedCookies: string) => {
            if (acceptedCookies === this.constants.REFUSE_COOKIE_NAV) {
                this.router.navigate([this.constants.URL_REFUS_COOKIES]);
            }
        });
    }

    ngOnDestroy(): void {
        this.mobileService.removeListener(this.isMobile);
    }
}
