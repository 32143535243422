import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AppConfig } from '../../../../core/services/app-config.service';
import { CguComponent } from '../../../../shared/components/content/cgu/cgu.component';
import { Constants } from '../../../../core/services/constants.service';
import { StorageService } from '../../../../core/services/storage.service';

@Component({
    selector: 'app-cookies-bottom-sheet',
    templateUrl: './cookies-bottom-sheet.component.html',
    styleUrls: ['./cookies-bottom-sheet.component.scss']
})
export class CookiesBottomSheetComponent implements OnInit {
    rsc: any;
    hostName: string;
    domainName: string;

    /**
     * Build an instance of CookiesBottomSheetComponent.
     * 
     * @param {AppConfig} appConfig 
     * @param {MatBottomSheetRef<CookiesBottomSheetComponent>} _bottomSheetRef 
     * @param {MatDialog} dialog
     * @param {Constants} constants
     * @param {StorageService} storageService
     * 
     */
    constructor(
        private appConfig: AppConfig,
        private _bottomSheetRef: MatBottomSheetRef<CookiesBottomSheetComponent>,
        private dialog: MatDialog,
        private constants: Constants,
        private storageService: StorageService
    ) {
    }

    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.layout.footer.cookies;
    }

    /**
     * Emmet le choix de consentement aux cookies : 'accept' ou 'refuse'
     * 
     * @param {Any} event
     *
     * @memberof CookiesBottomSheetComponent
     */
    consentCookies(event: any): void {
        if (event) {
            this.setLocalStorageCookieNavigation(event);
            this._bottomSheetRef.dismiss(event);
        }
    }

    /**
     * Définit dans le local storage si le cookie de navigation
     * est accepté ou refusé en fonction du choix de l'utilisateur
     * 
     * @param {Any} event 
     * 
     * @memberof CookiesBottomSheetComponent
     */
    setLocalStorageCookieNavigation(event: any): void {
        if (event === this.rsc.refuseValue) {
            this.storageService.storeInLocalStorage(this.constants.COOKIE_NAV, this.constants.REFUSE_COOKIE_NAV);
        } else {
            this.storageService.storeInLocalStorage(this.constants.COOKIE_NAV, this.constants.ACCEPT_COOKIE_NAV);
        }
    }

    /**
     * Gestion de clique sur un lien : redirection vers composnat CGU
     *
     * @memberof CookiesBottomSheetComponent
     */
    openLink(): void {
        this.dialog.open(CguComponent);
    }
}
