import { _habilitations } from "./habilitations";

export const _menu = {
  admin: {
    menuItems: [
      {
        url: "utilisateurs",
        name: "Utilisateurs",
        iconType: "i-feather",
        icon: "shield",
        type: "link"
      },
      {
        url: "locataires",
        name: "Locataires",
        iconType: "i-feather",
        icon: "users",
        type: "link"
      },
      {
        url: "stats",
        name: "Statistiques",
        iconType: "i-feather",
        icon: "bar-chart",
        type: "link",
        ressourceName: _habilitations.statistiques
      },
      {
        url: "notifications",
        name: "Notifications",
        iconType: "mat-icon",
        icon: "insert_comment",
        type: "link",
        ressourceName: _habilitations.notifications
      },
      {
        url: "new-notification",
        name: "Notifications",
        iconType: "mat-icon",
        icon: "insert_comment",
        type: "hidden"
      },
      {
        url: "edit-notification",
        name: "Notifications",
        iconType: "mat-icon",
        icon: "insert_comment",
        type: "hidden"
      },
      {
        url: "modeles",
        name: "Modèles",
        iconType: "mat-icon",
        icon: "bookmarks",
        type: "link",
        ressourceName: _habilitations.modeles
      },
      {
        url: "new-modele",
        name: "Modèles",
        iconType: "mat-icon",
        icon: "bookmarks",
        type: "hidden"
      },
      {
        url: "logs",
        name: "Logs",
        iconType: "i-feather",
        icon: "file-text",
        type: "link",
        ressourceName: "Logs"
      },
      {
        url: "parametrages",
        name: "Paramètrages",
        iconType: "i-feather",
        icon: "settings",
        type: "sub",
        ressourceName: _habilitations.parametrages,
        children: [
          {
            url: "cgu",
            name: "CGU",
            iconType: "i-feather",
            icon: "settings",
            type: "link"
          },
          {
            url: "maintenance",
            name: "Mise en maintenance",
            iconType: "i-feather",
            icon: "settings",
            type: "link"
          }
        ]
      }
    ],
    buttons: {
      google: {
        url: "https://analytics.google.com/analytics/web/#/p264232833/reports/defaulthome",
        name: "Google Analytics",
        iconType: "font-awesome",
        icon: "fab fa-google",
        type: "link"
      },
      deconnexion: {
        url: "deconnexion",
        name: "Déconnexion",
        iconType: "i-feather",
        icon: "power"
      },
      connectAs: {
        url: "connectAs",
        name: "Se connecter en tant que",
        iconType: "i-feather",
        icon: "user"
      }
    }
  },
  locataire: {
    menuItems: [
      {
        url: "tableau-de-bord",
        name: "Accueil",
        iconType: "i-feather",
        icon: "grid",
        type: "link"
      },
      {
        url: "profil",
        name: "Infos personnelles",
        iconType: "i-feather",
        icon: "user",
        type: "link",
        ressourceName: _habilitations.infosPerso
      },
      {
        url: "infos-pratiques",
        name: "Infos pratiques",
        iconType: "i-feather",
        icon: "info",
        type: "link",
        ressourceName: _habilitations.infosPratiques
      },
      {
        url: "liens-utiles",
        name: "Liens utiles",
        iconType: "i-feather",
        icon: "file",
        type: "link",
        ressourceName: _habilitations.liensUtiles
      },
      {
        url: "solde",
        name: "Paiement",
        iconType: "mat-icon",
        icon: "euro_symbol",
        type: "link",
        ressourceName: _habilitations.paiement
      },
      {
        url: "aucun-contrat",
        name: "Aucun contrat actif",
        iconType: "i-feather",
        icon: "info",
        type: "hidden"
      },
      {
        url: "success",
        name: "Résultat de paiement",
        iconType: "mat-icon",
        icon: "credit_card",
        type: "hidden"
      },
      {
        url: "error",
        name: "Résultat de paiement",
        iconType: "mat-icon",
        icon: "credit_card",
        type: "hidden"
      },
      {
        url: "refused",
        name: "Résultat de paiement",
        iconType: "mat-icon",
        icon: "credit_card",
        type: "hidden"
      },
      {
        url: "",
        name: "ListeDocuments",
        iconType: "i-feather",
        icon: "file",
        type: "sub",
        children: [
          {
            url: "compte",
            name: "Relevés de compte",
            type: "link",
            iconType: "i-feather",
            icon: "file",
            ressourceName: _habilitations.compte
          },
          {
            url: "documents",
            name: "Documents",
            type: "link",
            iconType: "i-feather",
            icon: "file",
            ressourceName: _habilitations.documents
          }
        ]
      },
      {
        url: "assurance",
        name: "Assurance habitation",
        iconType: "i-feather",
        icon: "home",
        type: "link",
        ressourceName: _habilitations.assurance
      },
      {
        url: "compteur",
        name: "Compteur",
        iconType: "i-feather",
        icon: "droplet",
        type: "link",
        ressourceName: _habilitations.compteur
      },
      {
        url: "preferences",
        name: "Mes préférences",
        iconType: "i-feather",
        icon: "rss",
        type: "link"
      },
      {
        url: "demandes",
        name: "Demandes",
        iconType: "i-feather",
        icon: "message-square",
        type: "link",
        ressourceName: _habilitations.demande
      }
    ],
    menuItemsDette: [
      {
        url: "tableau-de-bord",
        name: "Accueil",
        iconType: "i-feather",
        icon: "grid",
        type: "link"
      },
      {
        url: "infos-pratiques",
        name: "Infos pratiques",
        iconType: "i-feather",
        icon: "info",
        type: "link",
        ressourceName: _habilitations.infosPratiques
      },
      {
        url: "liens-utiles",
        name: "Liens utiles",
        iconType: "i-feather",
        icon: "file",
        type: "link",
        ressourceName: _habilitations.liensUtiles
      },
      {
        url: "solde",
        name: "Paiement",
        iconType: "mat-icon",
        icon: "euro_symbol",
        type: "link",
        ressourceName: _habilitations.paiement
      },
      {
        url: "success",
        name: "Résultat de paiement",
        iconType: "mat-icon",
        icon: "credit_card",
        type: "hidden"
      },
      {
        url: "error",
        name: "Résultat de paiement",
        iconType: "mat-icon",
        icon: "credit_card",
        type: "hidden"
      },
      {
        url: "refused",
        name: "Résultat de paiement",
        iconType: "mat-icon",
        icon: "credit_card",
        type: "hidden"
      },
      {
        url: "",
        name: "ListeDocuments",
        iconType: "i-feather",
        icon: "file",
        type: "sub",
        children: [
          {
            url: "compte",
            name: "Relevés de compte",
            type: "link",
            iconType: "i-feather",
            icon: "file",
            ressourceName: _habilitations.compte
          },
          {
            url: "documents",
            name: "Documents",
            type: "link",
            iconType: "i-feather",
            icon: "file",
            ressourceName: _habilitations.documents
          }
        ]
      },
      {
        url: "preferences",
        name: "Mes préférences",
        iconType: "i-feather",
        icon: "rss",
        type: "link"
      }
    ],
    buttons: {
      accessibility: {
        url: "accessibility",
        name: "Accessibilité",
        iconType: "font-awesome",
        icon: "fa fa-low-vision"
      },
      deconnexion: {
        url: "deconnexion",
        name: "Déconnexion",
        iconType: "i-feather",
        icon: "power"
      },
      contact: {
        url: "contact",
        name: "Contactez-nous",
        iconType: "mat-icon",
        icon: "chat_bubble_outline"
      },
      notifications: {
        url: "notifications",
        name: "Notifications",
        iconType: "i-feather",
        icon: "bell"
      },
      reglerLoyer: {
        url: "regler-loyer",
        name: "Régler mon loyer",
        iconType: "mat-icon",
        icon: "euro_symbol"
      },
      editAssurance: {
        url: "edit",
        name: "Assurance habitation",
        iconType: "mat-icon",
        icon: "home"
      },
      parametres: {
        url: "parametres",
        name: "Paramètres",
        iconType: "i-feather",
        icon: "mail"
      },
      pwa: {
        iconType: "i-feather",
        name: "Installer l'application",
        icon: "download",
        title: "Installer l'application"
      },
      editMontant: {
        url: "edit-montant",
        name: "Régler mon loyer",
        iconType: "mat-icon",
        icon: "euro_symbol"
      },
      prelevementEnvoi: {
        url: "envoi",
        name: "Prélèvement automatique",
        iconType: "mat-icon",
        icon: "credit_card"
      },
      prelevementAutomatique: {
        url: "prelevement",
        name: "Prélèvement automatique",
        iconType: "mat-icon",
        icon: "credit_card"
      }
    }
  }
}

export const infoPratiques = {
  title: "Bénéficier des informations pratiques",
  url: "infos-pratiques",
  ressource: _habilitations.infosPratiques
}

export const prelevementAuto = {
  title: "Créer un prélèvement automatique",
  url: "prelevement",
  ressource: _habilitations.paiement
}

export const payerLoyer = {
  title: "Payer mon loyer",
  url: "regler-loyer",
  ressource: _habilitations.paiement
}

export const assuranceHabitation = {
  title: "Mettre à jour mon assurance habitation",
  url: "assurance",
  ressource: _habilitations.assurance
}

export const infoPerso = {
  title: "Mettre à jour mes informations personnelles",
  url: "profil",
  ressource: _habilitations.infosPerso
}

export const liensUtiles = {
  title: "Liens utiles",
  url: "liens-utiles",
  ressource: _habilitations.liensUtiles
}

export const demandes = {
  ressource: _habilitations.demande
}

export const _faq = {
  links: [
    payerLoyer,
    prelevementAuto,
    assuranceHabitation,
    infoPratiques,
    infoPerso
  ],
  linksLocataireDette: [
    payerLoyer,
    prelevementAuto,
    infoPratiques,
  ],
  linksEnTantQue: [
    payerLoyer,
    assuranceHabitation,
    infoPratiques,
    infoPerso
  ]
}
