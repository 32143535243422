export const _browser = {
    chrome: 'Chrome',
    versionSupportChrome: 77,
    firefox: 'Firefox',
    versionSupportFirefox: 45,
    opera: 'Opera',
    versionSupportOpera: 36,
    edge: 'Edge',
    versionSupportEdge: 12,
    safari: 'Safari',
    versionSupportSafari: 10,
    IE: 'IE',
    messageLabel1: 'La version ',
    messageLabel2: ' de votre navigateur ',
    messageLabel3: ' n\'est plus supportée. Afin d\'accèder à l\'espace locataire, merci de mettre à jour votre navigateur.'
}