import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ParametresService {

    /**
     * Build an instance of ParametresService.
     * @param {HttpClient} http
     */
    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Appelle l'API pour mettre a jour le choix de dematerialisation
     * @memberof ParametresService
     */
    postDemat(payload: { numeroCA: string, codeOrganisme: number, numeroLocataire: string, isDemat: boolean }): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/ca/demat`, payload);
    }
}
