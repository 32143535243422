import { Injectable } from '@angular/core';

/**
 * Application global constants class
 *
 * @export
 * @class Constants
 */
@Injectable({
    providedIn: 'root',
})
export class Constants {
    public readonly B2C_ERROR_CANCEL_ENTRY_CODE = 'AADB2C90091';
    public readonly APP_AUTH = 'authentication';
    public readonly APP_USER = 'app_user';
    public readonly APP_USER_ID = 'app_user_id';
    public readonly APP_USER_EMAIL = 'app_user_email';
    public readonly APP_ADMIN_EMAIL = 'app_admin_mail';
    public readonly APP_USER_EMAIL_LOGGED_AS = 'app_user_mail_logged_as';
    public readonly LOGGED_IN = 'logged_in';
    public readonly LOGGED_AS_LOCATAIRE = 'logged_as_locataire';
    public readonly HABILITATIONS = "habilitations";
    public readonly DARK_THEME = 'dark-theme';
    public readonly LIGHT_THEME = 'light-theme';
    public readonly NB_DECIMAUX_EURO = 2;
    public readonly MONTANT_KEY = 'montant';
    public readonly ID_CONTRAT_KEY = 'id_contrat';
    public readonly NUMERO_CONTRAT = 'numero_contrat';
    public readonly ID_SOCIETE = 'id_societe';
    public readonly STATUT_EN_COURS = 'en cours';
    public readonly PRELEVEMENT_ACTIF = 'prelevement_actif';
    public readonly URL_AUCUN_CONTRAT = '/locataire/aucun-contrat';
    public readonly MAP_THEME = new Map([
        [true, this.DARK_THEME],
        [false, this.LIGHT_THEME],
    ]);
    public readonly LOT_ASSURANCE = "lot_assurance";
    public readonly PERSONNE_PHYSIQUE = "Physique";
    public readonly PERSONNE_MORALE = "Morale";
    public readonly TYPE_LOCATAIRE = new Map([
        [0, this.PERSONNE_PHYSIQUE],
        [1, this.PERSONNE_MORALE],
    ]);
    public readonly AT_LEAST_ONE_REQUIERED_KEY: "atLeastOneRequired";
    public readonly COMPARE_DATES_KEY = "dateDebutAvantDatefin";
    public readonly NOTIF_STATUS_SENT = "Envoyée";
    public readonly NOTIF_STATUS_EXPIRED = "Expirée";
    public readonly NOTIF_STATUS_PLANNED = "Planifiée";
    public readonly ORGANISME = "Organisme";
    public readonly HO1 = "HO1";
    public readonly HO2 = "HO2";
    public readonly HO3 = "HO3";
    public readonly HP1 = "HP1";
    public readonly HP2 = "HP2";
    public readonly HP3 = "HP3";
    public readonly ORGANISME_PLURAL = "organisme(s)";
    public readonly SOCIETE = 'societe';
    public readonly ORGANISME_DERNIER_NIVEAU = this.HP3;
    public readonly NIVEAUX_ORGANISMES = new Map([
        [this.ORGANISME, 6],
        [this.HO1, 5],
        [this.HO2, 4],
        [this.HO3, 3],
        [this.HP1, 2],
        [this.HP2, 1]
    ]);
    public readonly ORGANISMES_PARENT_CHILD = new Map([
        [this.ORGANISME, this.HO1],
        [this.HO1, this.HO2],
        [this.HO2, this.HO3],
        [this.HO3, this.HP1],
        [this.HP1, this.HP2],
        [this.HP2, this.HP3]
    ]);
    public readonly ORGANISMES_CHILD_PARENT = new Map([
        [this.HO1, this.ORGANISME],
        [this.HO2, this.HO1],
        [this.HO3, this.HO2],
        [this.HP1, this.HO3],
        [this.HP2, this.HP1],
        [this.HP3, this.HP2]
    ]);
    public readonly ORGANISMES_FILTRES = new Map([
        [this.ORGANISME, this.SOCIETE],
        [this.HO1, this.HO1],
        [this.HO2, this.HO2],
        [this.HO3, this.HO3],
        [this.HP1, this.HP1],
        [this.HP2, this.HP2],
        [this.HP3, this.HP3]
    ]);
    public readonly ORGANISMES_TO_MODELE_BACK = new Map([
        [this.ORGANISME, 'organisme_CiblesNotification'],
        [this.HO1, 'hO1_CiblesNotification'],
        [this.HO2, 'hO2_CiblesNotification'],
        [this.HO3, 'hO3_CiblesNotification'],
        [this.HP1, 'hP1_CiblesNotification'],
        [this.HP2, 'hP2_CiblesNotification'],
        [this.HP3, 'hP3_CiblesNotification']
    ]);
    public readonly ORGANISMES_FROM_MODELE_BACK = new Map([
        ['organisme_CiblesNotification', this.ORGANISME],
        ['hO1_CiblesNotification', this.HO1],
        ['hO2_CiblesNotification', this.HO2],
        ['hO3_CiblesNotification', this.HO3],
        ['hP1_CiblesNotification', this.HP1],
        ['hP2_CiblesNotification', this.HP2],
        ['hP3_CiblesNotification', this.HP3]
    ]);
    public readonly STATUT_BROUILLON = 'Brouillon';
    public readonly ACCEPT_COOKIE_NAV = 'accept';
    public readonly REFUSE_COOKIE_NAV = 'refuse';
    public readonly COOKIE_NAV = 'cookieNavigation';
    public readonly POSE_COMPTEUR = 'P';
    public readonly CONTRATS_PARTIS_ONLY = 'contrats_partis_only';
    public readonly APP_USER_ORGANISME = 'app_user_organisme';
    public readonly ENV_LOCAL = 'localhost';
    public readonly URL_LOCATAIRE = '/locataire';
    public readonly URL_AUTHENTICATION = '/authentication';
    public readonly URL_HOME = '/';
    public readonly URL_REFUS_COOKIES = '/refus-cookies';
}
