import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppConfig } from '../../../core/services/app-config.service';
import { AuthService } from '../../../core/services/auth.service';
import { Constants } from '../../../core/services/constants.service';
import { Contrat } from '../../models/contrat';
import { ContratService } from '../../services/contrat.service';
import { ErrorService } from '../../../core/services/error.service';
import { Router } from '@angular/router';
import { SharedContrats } from '../../models/shared-contrats';
import { StorageService } from '../../../core/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { _configOrganismes } from '../../../accessibility/config/organismes-locataire';
import { DataSharedService } from '../../services/data-shared.service';

@Component({
    selector: 'app-contrats',
    templateUrl: './contrats.component.html',
    styleUrls: ['./contrats.component.scss']
})
export class ContratsComponent implements OnInit {
    @Input() requiredResource: string;
    @Output() onSelectContrat: EventEmitter<any>;

    rsc: any;
    contrats: Array<Contrat>;
    libelleDefaultValue: string;
    selectedID: number;
    selectedNumeroCA: string;
    isLoading: boolean;
    isError: boolean;
    numeroTiers: string;
    isListeContratsClean: boolean = false;

    /**
     * Build an instance of AppContratsComponent.
     * 
     * @param {AppConfig} appConfig 
     * @param {Constants} constants 
     * @param {StorageService} storageService 
     * @param {ContratService} contratService 
     * @param {NotificationService} notificationService 
     * @param {ErrorService} errorService 
     * @param {AuthService} authService 
     * @param {Router} router
     * @param {DataSharedService} dataSharedService
     * 
     * @memberof ContratsComponent
     */
    constructor(
        private appConfig: AppConfig,
        private constants: Constants,
        private storageService: StorageService,
        private contratService: ContratService,
        private notificationService: ToastrService,
        private errorService: ErrorService,
        private authService: AuthService,
        private router: Router,
        private dataSharedService: DataSharedService

    ) {
        this.onSelectContrat = new EventEmitter<SharedContrats>();
    }

    /**
     * Initializes ContratsComponent
     *
     * @memberof ContratsComponent
     */
    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.shared.contrats;
        this.libelleDefaultValue = this.rsc.defaultValue;

        if (this.authService.isLoggedAs()) {
            let user = this.storageService.getFromSessionStorage(this.constants.APP_USER);
            this.numeroTiers = user.numero_Tiers;
        }

        this.isLoading = true;
        this.isListeContratsClean = false;
        let unfilteredContrats;
        this.contratService.getContrats(this.numeroTiers).subscribe(
            contrats => {
                const contratsPartisUniquement = this.storageService.getFromSessionStorage(this.constants.CONTRATS_PARTIS_ONLY);
                const auMoinsUnContratParti = this.contratService.findContratParti(true, contrats);

                if (contratsPartisUniquement === true || auMoinsUnContratParti) {
                    if ((this.isUrlContratPartis())) {
                        this.cleanContratsList(contrats);
                    } else {
                        unfilteredContrats = contrats;
                    }
                } else {
                    unfilteredContrats = contrats;
                }

                if(this.requiredResource) {
                    let userhabilitations = this.storageService.getFromSessionStorage(this.constants.HABILITATIONS);
                    unfilteredContrats = contrats.filter(c => {
                        if(userhabilitations[c.numero_CA] && userhabilitations[c.numero_CA][this.requiredResource])
                            return c;
                    });
                }
                this.contrats = unfilteredContrats;

                this.setDefaultValue();
            },
            error => {
                this.isLoading = false;
                /**
                 *  Ceci est un exemple de gestion d'erreur
                 *  Le isError va permettre d'afficher par le message d'erreur via app-loading-and-error
                 *  L'appel à notificationService permet lui de réafficher la popup de notification en bas à droite de l'écran
                 *  En effet lorsqu'une récupération de l'erreur est faite dans le subscribe, elle est considérée traitée.
                 *  Le traitement générique à toute erreur d'un appel HTTP géré par le GlobalErrorHandler n'est pas effectué
                 *  S'il on souhaite avoir l'affichage de la div d'erreur ET de la notification d'erreur
                 *  il faut donc réafficher celle-ci manuellement
                 */
                this.isError = true;

                let errorStatus = error.error.statusCode;

                if (errorStatus !== 404) {
                    this.notificationService.error(this.errorService.getErrorMessage(error));
                }
            }
        );
    }

    /**
     * Envoie au parent la liste des contrats récupérée via API
     * ainsi que le contrat sélectionné
     *
     * @memberof ContratsComponent
     */
    sendContratsAndSeletedOne(): void {
        let selectedContrat;
        // Si le contrat sélectionné a pour ID 0 alors il s'agit du contrat par défaut
        // Sinon on cherche le contrat correspondant à cet ID, s'il n'existe pas on retourne le contrat par défaut
        if (this.selectedID === 0) {
            selectedContrat = this.contrats[0];
        } else {
            const contrat = this.contratService.getContratById(this.contrats, this.selectedID);
            selectedContrat = contrat ?? contrat;
        }

        if (selectedContrat) {
            const idOrganismeLocataire = selectedContrat.id_Organisme;
            const infosOrganismeLocataire = _configOrganismes.find(item => item.id === idOrganismeLocataire);

            if (infosOrganismeLocataire) {
                this.storageService.storeInSessionStorage(this.constants.APP_USER_ORGANISME, infosOrganismeLocataire.code);
            }
        }

        this.selectPremierContratActif();

        this.onSelectContrat.emit({
            selectedID: this.selectedID,
            selectedNumeroCA: this.selectedNumeroCA,
            selectedContrats: [selectedContrat],
            contrats: this.contrats
        });

        if (selectedContrat) {
            this.dataSharedService.isContratSelected(true);
        }
    }

    /**
     * Affiche le premier contrat actif
     * si le contrat sélectionné n'est pas actif
     * 
     * 
     * @memberof ContratsComponent
     */
    selectPremierContratActif(): void {
        if (this.isSetListeContratsPourAssurance()) {
            const contratSelectionneActif = this.contrats.find(contrat => contrat.id_CA === this.selectedID);
            if (!contratSelectionneActif) {
                this.selectedID = this.contrats[0].id_CA;
            }
        }
    }

    /**
     * Vérifie si la liste des contrats
     * peut être nettoyer ou non
     * 
     * @returns boolean
     * 
     * @memberof ContratsComponent
     */
    isSetListeContratsPourAssurance(): boolean {
        return this.isListeContratsClean && this.router.url === '/locataire/assurance' && this.selectedID !== 0;
    }

    /**
     * Assigne la valeur par défaut du contrat sélectionné
     * - Si hideAllContrat = true => "Tous mes contrats" dont l'id vaut 0
     * - Sinon le 1er contrat disponible
     *
     * @memberof ContratsComponent
     */
    setDefaultValue(): void {
        this.selectedID = this.contratService.getSelectedContratId();
        const idCA = this.contrats.find(element => element.id_CA === this.selectedID);
        if (this.selectedID !== 0 && idCA !== undefined) {
            this.selectedNumeroCA = this.contratService.getContratById(this.contrats, this.selectedID).numero_CA;
        }

        if (this.contrats && this.contrats.length > 0) {
            if (!this.selectedID) {
                this.selectedID = this.contrats[0].id_CA;
                this.selectedNumeroCA = this.contrats[0].numero_CA;
            }
        }
        
        if(this.selectedNumeroCA)
            this.storageService.storeInSessionStorage(this.constants.NUMERO_CONTRAT, this.selectedNumeroCA);

        this.isLoading = false;
        this.sendContratsAndSeletedOne();
    }

    /**
     * Emet une action d'envoi de données au parent
     * à chaque nouvelle sélection
     *
     * @memberof ContratsComponent
     * @param idContrat
     */
    onSelect(idContrat: any): void {
        if (idContrat === 0) {
            this.storageService.removeFromSessionStorage(this.constants.ID_CONTRAT_KEY);
        } else {
            this.storageService.storeInSessionStorage(this.constants.ID_CONTRAT_KEY, idContrat);
        }
        this.selectedID = idContrat;
        this.selectedNumeroCA = this.contratService.getContratById(this.contrats, idContrat)?.numero_CA;
        this.storageService.storeInSessionStorage(this.constants.NUMERO_CONTRAT, this.selectedNumeroCA);
        this.sendContratsAndSeletedOne();
    }

    /**
     * Récupération de l'adresse du contrat, via le lot principal s'il existe
     * Sinon retourne l'adresse CA
     *
     * @param contrat
     * @memberof ContratsComponent
     */
    getLibelleContrat(contrat: Contrat): string {
        return this.contratService.getLibelleContrat(contrat);
    }

    /**
     * Récupère le numéro du contrat
     *
     * @param contrat
     * @memberof ContratsComponent
     */
    getNumeroContrat(contrat: Contrat): string {
        return this.contratService.getNumeroContrat(contrat);
    }

    /**
     * Nettoie la liste des contrats
     * en gardant uniquement les contrats actifs
     * 
     * @param {Contrat[]} contrats
     * @memberof ContratsComponent
     */
    cleanContratsList(contrats: Contrat[]): void {
        this.contrats = [];
        this.contrats = contrats.filter(contratActif => contratActif.estParti === false);
        this.isListeContratsClean = true;
    }

    /**
     * Vérifie le type de route pour les contrats partis
     * 
     * @memberof ContratsComponent
     * @returns boolean
     */
    isUrlContratPartis(): boolean {
        return this.router.url === '/locataire/profil' || this.router.url === '/locataire/compteur' || this.router.url === '/locataire/assurance';
    }

}
