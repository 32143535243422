import { AfterContentChecked, Directive } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
    selector: '[appAccordion]',
})
export class AccordionDirective implements AfterContentChecked {
    protected navlinks: Array<AccordionLinkDirective> = [];

    constructor(public router: Router) {
        setTimeout(() => this.checkOpenLinks());
    }

    closeOtherLinks(selectedLink: AccordionLinkDirective): void {
        this.navlinks.forEach((link: AccordionLinkDirective) => {
            if (link !== selectedLink) {
                link.selected = false;
            }
        });
    }

    addLink(link: AccordionLinkDirective): void {
        this.navlinks.push(link);
    }

    removeGroup(link: AccordionLinkDirective): void {
        const index = this.navlinks.indexOf(link);
        if (index !== -1) {
            this.navlinks.splice(index, 1);
        }
    }

    checkOpenLinks() {
        this.navlinks.forEach((link: AccordionLinkDirective) => {
            if (link.group) {
                const routeUrl = this.router.url;
                const currentUrl = routeUrl.split('/');
                if (currentUrl.includes(link.group)) {
                    link.selected = true;
                    this.closeOtherLinks(link);
                }
            }
        });
    }

    ngAfterContentChecked(): void {
        this.router.events.pipe(
                filter(event => event instanceof NavigationEnd))
                .subscribe(e => this.checkOpenLinks());
    }
}
