import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';

import { AccessibilityComponent } from '../../accessibility/components/accessibility.component';
import { AppConfig } from '../../core/services/app-config.service';
import { AuthService } from '../../core/services/auth.service';
import { BeforeInstallPromptEvent } from '../../../typings';
import { Constants } from '../../core/services/constants.service';
import { ContratService } from '../../shared/services/contrat.service';
import { IMenu } from '../../shared/models/menu';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MixinService } from '../../core/services/mixin.service';
import { MobileService } from '../../core/services/mobile.service';
import { ParametresComponent } from '../../features/locataire/parametres/components/parametres.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Platform } from '@angular/cdk/platform';
import { PromptComponent } from '../../shared/components/prompt/prompt.component';
import { Router } from '@angular/router';
import { StorageService } from '../../core/services/storage.service';
import { take } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { DataSharedService } from '../../shared/services/data-shared.service';
import { _menu } from '../../shared/constants/habilitation-items';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class AppSidebarComponent implements AfterViewInit, OnInit, OnDestroy {
    @Output() onClickOnMenuItem: EventEmitter<boolean>;

    config: PerfectScrollbarConfigInterface = {};
    isMobile: MediaQueryList;
    showInstallAppButton: boolean;
    hasContratsActifs: boolean;
    defferedPromptPWA: BeforeInstallPromptEvent;
    menuItems: IMenu[];
    safeMenuItems: IMenu[];
    menuItemsDette: IMenu[];
    safeMenuItemsDette: IMenu[];
    rsc: any;
    userType: string;
    isLoggedAs: boolean;

    status = false;
    userName: string;
    baseUrl: string;
    onAucunContratPage: boolean;
    hasMenuItems: boolean = false;

    listerDroitsAdminSubscription: Subscription;

    @Input() userNameLocataire?: string;

    clickEvent() {
        this.status = !this.status;
    }

    subclickEvent() {
        this.status = true;
    }

    onSelectedContrat: Subscription;

    /**
     * Build an instance of AppSidebarComponent.
     * @param {Router} router
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {AppConfig} appConfig
     * @param {AuthService} authService
     * @param {MixinService} mixinService
     * @param {MatDialog} dialog
     * @param {MobileService} mobileService
     * @param {StorageService} storageService
     * @param {Constants} constants
     * @param {Platform} platform
     * @param {BottomSheet} bottomSheet
     * @param {ContratService} contratService
     * @param {DataSharedService} dataSharedService
     * 
     * @memberof AppSidebarComponent
     */
    constructor(
        public router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private appConfig: AppConfig,
        private authService: AuthService,
        private mixinService: MixinService,
        private dialog: MatDialog,
        private mobileService: MobileService,
        private storageService: StorageService,
        private constants: Constants,
        private platform: Platform,
        private bottomSheet: MatBottomSheet,
        private contratService: ContratService,
        private dataSharedService: DataSharedService

    ) {
        this.isMobile = this.mobileService.mobileQueryListener(this.changeDetectorRef, '768');
        this.showInstallAppButton = false;
        this.onClickOnMenuItem = new EventEmitter<boolean>();
    }

    onButtonClick(url: string) {
        this.router.navigate([this.authService.getBaseUrl() + '/' + url]);
        this.closeMenu();
    }

    ngOnInit() {
        this.userType = this.authService.getUserType();
        if (this.userType) {
            this.rsc = _menu[this.userType];
            if (!this.authService.isAdmin()) {
                this.setMenusItemsWithoutPreferences();
            }
            this.mixinService.setNavigationMenus(_menu, this.userType);
        } else {
            this.authService.logout();
        }

        this.baseUrl = this.authService.getBaseUrl();
        this.isLoggedAs = this.authService.isLoggedAs();
        this.checkOnContratPage();

        if (this.authService.isAdmin() && !this.authService.isLoggedAs()) {
            this.menuItems = this.rsc.menuItems;
            this.userName = 'Admin';
            this.setDroitsAdmin();
        } else {
            if (this.authService.isLoggedAs())
            {
                this.setMenusItemsWithoutPreferences();
            }
            this.hasContratsActifs = this.router.url !== this.constants.URL_AUCUN_CONTRAT;
            this.userName = this.userNameLocataire;

            this.calculSidebar();
            this.defineMenuBySelectedContrat();
        }

        if (this.platform.IOS) {
            const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
            if (!isInStandaloneMode) {
                const isConsented = this.storageService.getFromLocalStorage(this.constants.COOKIE_NAV);

                if ((this.storageService.getFromLocalStorage(this.constants.COOKIE_NAV) === this.constants.REFUSE_COOKIE_NAV || !isConsented) && !this.authService.isAdmin() && this.authService.isLoggedIn()) {
                    this.openPromptComponent();
                }

            }
        }
        else {
            // Check si la PWA est installée
            // Si non -> le bouton d'installation est affiché
            window.addEventListener('beforeinstallprompt', (e: BeforeInstallPromptEvent) => {
                // Empêche l'infobar d'apparaître sur mobile
                // e.preventDefault();

                this.showInstallAppButton = true;
                // On stocke l'event qui va nous permettre d'afficher le prompt d'installation natif plus tard (via defferedPromptPWA.prompt())
                this.defferedPromptPWA = e;
            });

            window.addEventListener('appinstalled', (e) => {
                this.HideInstallAppButton();
            });
        }

    }

    openPromptComponent(): void {
        timer(3000)
            .pipe(take(1))
            .subscribe(() => {
                this.bottomSheet.open(PromptComponent, {
                    closeOnNavigation: false,
                    disableClose: true,
                    hasBackdrop: false,
                });
            });
    }

    /**
    * Vérifie les habilitations de l'utilisateur connecté afin de masquer les entrées menu auxquelles il n'a pas accès
    * 
    * @memberof AppSidebarComponent
    */
    setAuthorizedMenu(): void {
        let userHabilitations = this.mixinService.getCurrentContratHabilitations();
        let authorizedMenu = [];

        if(userHabilitations) {
            authorizedMenu = this.safeMenuItems.filter(menu => {
                if (!menu.ressourceName) {
                    // Gére l'affichage du menus ListeDocuments: relevés compte et documents
                    if (menu.name === "ListeDocuments") {
                        menu.children = menu.children.filter(sousMenu =>
                            sousMenu.ressourceName && userHabilitations[sousMenu.ressourceName]
                        );
                        return menu.children.length > 0;
                    }
                    // Masquer l'entrée "préférences" si elle n'est pas autorisée
                    return !(menu.url === 'preferences');
                }
    
                return userHabilitations[menu.ressourceName];
            });
        }

        this.menuItems = authorizedMenu;
    }

    setDroitsAdmin(): void {
        const droitsAdmin = {
            "droitAdmin": ["utilisateurs", "logs", "cgu", "maintenance"],
            "droitEnTantQue": ["locataires", "connectAs"],
            "droitNotification": ["notifications", "new-notification", "edit-notification",
                "modeles", "new-modele", "edit-modele"],
        }

        const mail = this.storageService.getFromSessionStorage(this.constants.APP_AUTH);
        let droits: Array<string> = [];
        const body = {
            pageNumber: 1,
            rowsPerPage: 200,
            filter: mail.account.username
        }
        this.listerDroitsAdminSubscription?.unsubscribe();
        this.listerDroitsAdminSubscription = this.authService.listerUtilisateurs(body).subscribe(res => {
            if (res && res.data) {
                res.data.forEach(element => {
                    if (element.droitAdmin && droits.indexOf("droitAdmin") === -1) droits.push("droitAdmin");
                    if (element.droitEnTantQue && droits.indexOf("droitEnTantQue") === -1) droits.push("droitEnTantQue");
                    if (element.droitNotification && droits.indexOf("droitNotification") === -1) droits.push("droitNotification");
                });

                Object.keys(droitsAdmin).forEach(droit => {
                    if (droits.indexOf(droit) === -1) {
                        this.menuItems.forEach((menu, index) => {
                            droitsAdmin[droit].forEach(pageDroit => {
                                if (menu.url === pageDroit) {
                                    this.menuItems.splice(index, 1);
                                }
                            });
                        });
                    }
                });
            }
        });
    }

    ngAfterViewInit() {
        if (this.isLaunchedAsPWA() === true) {
            this.HideInstallAppButton();
        }
    }

    /**
     * Supprime le mobile query listener à la destruction du composant
     *
     * @memberof AppSidebarComponent
     */
    ngOnDestroy(): void {
        this.mobileService.removeListener(this.isMobile);
        this.listerDroitsAdminSubscription?.unsubscribe();
    }

    deconnexion(): void {
        this.authService.logout();
    }

    openAccessibility(): void {
        this.closeMenu();
        this.dialog.open(AccessibilityComponent);
    }

    openParameters(): void {
        this.closeMenu();
        const dialogRef = this.dialog.open(ParametresComponent, { autoFocus: false });
        dialogRef.afterClosed().subscribe((resp: boolean) => {
            if (resp && this.router.url === '/locataire/documents') {
                this.mixinService.refresh();
            }
        });
    }

    openGoogleAnalytics(url: string): void {
        window.open(url, '_blank');
    }

    closeMenu(): void {
        this.onClickOnMenuItem.emit(true);
    }

    /**
     * Installe la PWA
     * 
     */
    installPWA(): void {
        if (this.defferedPromptPWA !== undefined && this.defferedPromptPWA !== null) {
            this.defferedPromptPWA.prompt();
            this.defferedPromptPWA.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    // L'utilisateur a accépté d'installer l'application
                    this.HideInstallAppButton();
                }
            });
        }
        else return;
    }

    HideInstallAppButton(): void {
        this.showInstallAppButton = false;
    }

    isLaunchedAsPWA(): boolean {
        let navigatorTSFix: any;

        navigatorTSFix = window.navigator;
        if (navigatorTSFix.standalone) {
            return true;
        }
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return true;
        }
        return false;
    }

    /**
     * Vérifie le type d'utilisateur est un locataire ou non
     * 
     * @returns boolean
     * @memberof AppSidebarComponent
     */
    isLocataire(): boolean {
        return this.userType === 'locataire';
    }

    /**
     * Définit si on est sur la page aucun contrat
     * 
     * @memberof AppSidebarComponent
     */
    checkOnContratPage(): void {
        this.onAucunContratPage = this.router.url === '/locataire/aucun-contrat';
    }

    /**
     * Définit les items de la sidebar en fonction des contrats
     * 
     * @memberof AppSidebarComponent
     */
    calculSidebar(): void {
        const numTiers = this.storageService.getFromSessionStorage(this.constants.APP_USER).numero_Tiers;

        this.contratService.getLocataireContrats(numTiers).subscribe(contrats => {
            const contratActif = this.contratService.findContratParti(false, contrats);
            this.menuItems = contratActif ? this.menuItems : this.menuItemsDette;
            this.setAuthorizedMenu();
            this.hasMenuItems = true;
        }
        );
    }

    /**
     * Personnalise le menu en fonction du contrat
     * sélectionné
     * 
     * @memberof SidebarComponent
     */
    defineMenuBySelectedContrat(): void {
        this.onSelectedContrat = this.dataSharedService.currentContratSelected.subscribe((isSelected) => {
            this.setAuthorizedMenu();
            this.hasMenuItems = true;
        });
    }

    /**
     * Définit les items des menus avec contrat et dette
     * en supprimant l'objet mes préférences
     * 
     * @memberof SidebarComponent
     */
    setMenusItemsWithoutPreferences(): void {
        this.menuItems = this.rsc.menuItems.filter((item: { url: string; }) => (item.url !== 'preferences'));
        this.menuItemsDette = this.rsc.menuItemsDette.filter((item: { url: string; }) => (item.url !== 'preferences'));
        // On stocke le menu dans des variables safe qui ne seront pas altérées
        this.safeMenuItems = [].concat(this.menuItems);
        this.safeMenuItemsDette = [].concat(this.menuItemsDette);
    }
}
