import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppConfig } from '../../core/services/app-config.service';
import { AuthService } from '../../core/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { MixinService } from '../../core/services/mixin.service';
import { IPageTitle } from '../../shared/models/menu';
import { MobileService } from '../../core/services/mobile.service';
import { _menu } from '../../shared/constants/habilitation-items';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
    public rsc: any;
    public config: PerfectScrollbarConfigInterface = {};
    public pageTitle: IPageTitle;
    userType: string;

    isMobileSmall: MediaQueryList;
    isTitleSmall: boolean;

    /**
     * Build an instance of AppHeaderComponent.
     * @param {AppConfig} appConfig
     * @param {AuthService} authService
     * @param {Router} router
     * @param {MixinService} mixinService
     * @param {MobileService} mobileService
     * @param {ChangeDetectorRef} changeDetectorRef
     * @memberof AppHeaderComponent
     */
    constructor(
        private appConfig: AppConfig,
        private authService: AuthService,
        public router: Router,
        private mixinService: MixinService,
        private mobileService: MobileService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.isMobileSmall = this.mobileService.mobileQueryListener(this.changeDetectorRef, '425');
    }

    ngOnInit() {
        this.rsc = this.appConfig.rsc.layout.header;
        this.isTitleSmall = false;
        this.userType = this.authService.getUserType();
        if (this.userType) {
            const urlTab = window.location.pathname;
            this.setPageTitle(urlTab);
            this.checkTitleSmall();

            // Abonnement aux routes pour savoir le nom de la page à afficher
            this.router.events.subscribe(
                event => {
                    if (event instanceof NavigationEnd) {
                        this.setPageTitle(event.url);
                        this.checkTitleSmall();
                    }
                });
        } else {
            this.authService.logout();
        }
    }

    /**
     * Affecte un nom à la page en fonction de sa position dans la liste navSidebar
     * 
     * @param {string} url
     * @memberof AppHeaderComponent
     */
    setPageTitle(url: string): void {
        let currentPage = url.split('/');
        if (currentPage.length > 0) {
            // Si le dernier paramètre de la route est un id
            let routeUrl: string = currentPage[currentPage.length - 1];
            if (!isNaN(+routeUrl)) {
                routeUrl = currentPage[currentPage.length - 2];
            }
            this.pageTitle = this.mixinService.getPageTitle(routeUrl, _menu, this.userType);
        }
    }

    /**
     * Vérifie la taille du titre dans le header
     * 
     * @memberof AppHeaderComponent
     */
    checkTitleSmall(): void {
        const maxLength = this.rsc.title.maxLength;

        if (this.pageTitle) {
            this.isTitleSmall = this.pageTitle.title.length <= maxLength;
        }
    }
}
