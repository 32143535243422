import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from '../../core/services/app-config.service';
import { Constants } from '../../core/services/constants.service';
import { StorageService } from '../../core/services/storage.service';
import { Locataire } from '../models/locataire';
import { CGUResponse } from '../models/cgu-response';
import { ErrorService } from '../../core/services/error.service';
import { SituationFamiliale } from '../../features/locataire/profil/models/sit-familiale';
import { SituationPro } from '../../features/locataire/profil/models/sit-pro';
import { MajSituationFamiliale } from '../../features/locataire/profil/models/maj-situation-familiale';
import { MajCoordonnees } from '../../features/locataire/profil/models/maj-coordonnees';
import { ExistLocataire } from '../models/exist-locataire';
import { SearchLocataire } from '../models/search-locataire';
import { InfosConnexionUser } from '../../features/admin/locataires/models/infos-connexion-user';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LocataireService {
    /**
     * Build an instance of LocataireService.
     *
     * @param {HttpClient} http
     * @param {Constants} constants
     * @param {AppConfig} appConfig
     * @param {StorageService} storageService
     * @param errorService
     * @memberof LocataireService
     */
    constructor(
        private http: HttpClient,
        private constants: Constants,
        private appConfig: AppConfig,
        private storageService: StorageService,
        private errorService: ErrorService
    ) {
    }

    /**
     * Fait appel à l'api pour récupérer les infos du locataire connecté
     *
     * @returns {Observable<Locataire>}
     * @memberof LocataireService
     */
    setLocataire(email?: string): Observable<Locataire> {

        const param = email || email !== undefined ? `${email}` : '';

        return this.http.get(`${environment.apiUrl?.protected}/locataire/${param}`)
            .pipe(
                map((user: Locataire) => {
                    this.saveLocataireData(user);
                    return user;
                }),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
     * Sauvegarde certaines infos du locataire dans session storage
     *
     * @memberof LocataireService
     */
    saveLocataireData(user: Locataire): void {
        if (user) {
            this.storageService.storeInSessionStorage(this.constants.APP_USER, user);
            this.storageService.storeInSessionStorage(this.constants.APP_USER_ID, user.id_Locataire);
        }
    }

    /**
     * Fait appel à l'api pour récupérer les infos du locataire connecté
     *
     * @returns {Observable<Locataire>}
     * @memberof LocataireService
     */
    getLocataire(): Observable<Locataire> {
        const user = this.storageService.getFromSessionStorage(this.constants.APP_USER) as Locataire;
        return user ? of(user) : this.setLocataire();
    }

    /**
     * Fait appel à l'api pour récupérer les infos du locataire connecté sans sauvegarde dans le session storage
     *
     * @returns {Observable<Locataire>}
     * @memberof LocataireService
     */
    setLocataireWithoutSave(): Observable<Locataire> {
        return this.http.get(`${environment.apiUrl?.protected}/locataire`)
            .pipe(
                map((user: Locataire) => {
                    return user;
                }),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
     * Récupérer l'id locataire du session storage
     *
     * @returns {number}
     * @memberof LocataireService
     */
    getLocataireId(): number {
        return +this.storageService.getFromSessionStorage(this.constants.APP_USER_ID);
    }

    /**
    * Récupérer la liste des situations familiales
    *
    * @returns {Array<SituationFamiliale>}
    * @memberof LocataireService
    */
    getSituationFamiliale(): Observable<Array<SituationFamiliale>> {
        return this.http
            .get(`${environment.apiUrl?.protected}/ref/situation-familiale`)
            .pipe(map((situation: Array<SituationFamiliale>) => situation),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
    * Récupérer la liste des situations professionnelles
    *
    * @returns {Array<SituationPro>}
    * @memberof LocataireService
    */
    getSituationPro(): Observable<Array<SituationPro>> {
        return this.http
            .get(`${environment.apiUrl?.protected}/ref/situation-pro`)
            .pipe(map((situation: Array<SituationPro>) => situation),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
     * Fait appel à l'api de mise à jour de la situation familliale de l'utilisateur
     *
     * @memberof LocataireService
     */
    majSituationFamiliale(situation: MajSituationFamiliale): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/MajSituationFam`, situation);
    }

    /**
    * Fait appel à l'api de mise à jour de la situation professionnelle de l'utilisateur
    *
    * @memberof LocataireService
    */
    majSituationPro(situation: any): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/MAJSituationPro`, situation);
    }

    /**
    * Récupérer l'info pour savoir si un locataire doit valider les CGU
    *
    * @param {boolean} isLoggedAs
    * @param {{CodeTiers: string, Email: string}} params?
    * @returns {Observable<CGUResponse>}
    * @memberof LocataireService
    */
    getValidationCGU(isLoggedAs: boolean, params?: { CodeTiers: string, Email: string }): Observable<CGUResponse> {
        if (isLoggedAs) {
            return this.getCGUEnTantQue(params);
        } else {
            return this.getCGULocataire();
        }
    }

    /**
    * Récupère l'info CGU côté locataire
    *
    * @returns {Observable<CGUResponse>}
    * @memberof LocataireService
    */
    getCGULocataire(): Observable<CGUResponse> {
        return this.http
            .get(`${environment.apiUrl?.protected}/cgu`)
            .pipe(map((cgu: CGUResponse) => cgu),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            );
    }

    /**
    * Récupère l'info CGU côté admin mode en tant que
    *
    * @param {{CodeTiers: string, Email: string}} params
    * @returns {Observable<CGUResponse>}
    * @memberof LocataireService
    */
    getCGUEnTantQue(params: { CodeTiers: string, Email: string }): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/cgu`, params);
    }

    /**
     * Fait appel à l'api de mise à jour de la verification des CGU
     *
     * @memberof LocataireService
     */
    majValidationCGU(): Observable<any> {
        return this.http.get(`${environment.apiUrl?.protected}/ValidationCGU`);
    }

    /**
    * Fait appel à l'api de mise à jour des coodronées téléphinques de l'utilisateur
    *
    * @memberof LocataireService
    */
    majCoordonnees(coordonnees: MajCoordonnees): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/MAJCoordonnees`, coordonnees);
    }

    /**
     * Fait appel à l'api en tant que locataire
     * 
     * @memberof LocataireService
     */
    isLoggedAs(mail: string): Observable<boolean> {
        return this.http.get(`${environment.apiUrl?.protected}/GetEnTantQue/${mail}`)
            .pipe(
                map((resp) => resp as boolean),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            )
    }

    /**
     * Récupére la liste des numéros tiers (locataires) existants
     * Affiche le nombre de numéros tiers validés et rejetés
     * 
     * @param {string[]} numerosTiers
     * @returns {Observable<ExistLocataire>}
     * 
     * @memberof LocataireService
     */
    getLocataireExist(numerosTiers: string[]): Observable<ExistLocataire> {
        return this.http.post(`${environment.apiUrl?.protected}/AdminUsers/VerifLocataireExiste`, numerosTiers);
    }

    /**
     * Fait appel à l'api et récupére la liste des locataires
     * 
     * @param {string} filteredSearch 
     * @returns {Observable<SearchLocataire>}
     * 
     * @memberof LocataireService
     */
    getRechercheLocataire(filteredSearch: string): Observable<SearchLocataire> {
        return this.http.get(`${environment.apiUrl?.protected}/AdminUsers/rechercheLocataire/${filteredSearch}`)
            .pipe(
                map((locataire: SearchLocataire) => locataire),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            )
    }

    /**
     * Fait appel à l'api pour blacklister un utilisateur
     * 
     * @param {string} idUtilisteur
     * @returns {Observable<any>}
     * 
     * @memberof LocataireService
     */
    setUserBlackList(idUtilisteur: string, isBlocked: boolean): Observable<any> {
        return this.http.post(`${environment.apiUrl?.protected}/AdminUsers/SetUserB2CBlockSignInValue/${idUtilisteur}?p_Blocked=${isBlocked}`, null);
    }

    /**
     * Met à jour la propriété "connected" pour indiquer 
     * que l'utilisateur s'est bien connecté la première fois
     * 
     * @param {string} idUtilisteur
     * @returns {Observable<any>}
     * 
     * @memberof LocataireService
     */
    updateFirstConnect(): Observable<any> {
        const options = {
            headers: {
                'Content-Type': 'application/json-patch+json',
            }
        }
        return this.http.post(`${environment.apiUrl?.protected}/locataire/first-connect`, true, options);
    }

    /**
     * Fait appel à l'api ref pour récupérer
     * la liste des informations de connexion
     * de l'utilisateur sélectionné
     * 
     * @param {String} email
     * @returns {Observable<Array<InfosConnexionUser>>}
     * 
     * @memberof LocataireService
     */
    getInfosConnexionUtilisateurs(email: string): Observable<Array<InfosConnexionUser>> {
        return this.http.get(`${environment.apiUrl?.protected}/AdminUsers/GetStatsUsersActivityByMailAsyncB2C/${email}`)
            .pipe(
                map((listeInfosConnexion: Array<InfosConnexionUser>) => listeInfosConnexion),
                catchError(err => {
                    return throwError(this.errorService.getErrorMessage(err));
                })
            )
    }
}
