import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '../../../core/services/app-config.service';
import { MobileService } from '../../../core/services/mobile.service';


@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {
    isMobile: MediaQueryList;
    rsc: any;
    redirectUrl: string;

    /**
     * Build an instance of PresentationComponent.
     * @param {MobileService} mobileService
     * @param {ChangeDetectorRef} changeDetectorRef
     * @param {AppConfig} appConfig
     * @param {Router} router
     * @memberof PresentationComponent
     */
    constructor(
        private mobileService: MobileService,
        private changeDetectorRef: ChangeDetectorRef,
        private appConfig: AppConfig,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.isMobile = this.mobileService.mobileQueryListener(this.changeDetectorRef, '700');
    }

    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.pages.presentation;
        this.activatedRoute.queryParams.subscribe(params => {
            this.redirectUrl = params.redirect;
        });
    }

    /**
     * Au click sur la croix -> redirection vers l'url de redirection
     * indiquée dans l'url courante
     * 
     * @memberof PresentationComponent
     */
    close(): void {
        const navigateUrl: string = this.redirectUrl === 'faq' ? this.rsc.url : '/locataire/' + this.redirectUrl;
        this.router.navigate([navigateUrl]);
    }

}
