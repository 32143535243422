import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../../core/services/app-config.service';
import { CguService } from '../../../services/cgu.service';

@Component({
    selector: 'app-cgu',
    templateUrl: './cgu.component.html',
    styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit {

    public rsc: any;
    public useTerms: any;

    mainTitle: string;

    constructor(
        private appConfig: AppConfig,
        private cguService: CguService
    ) { }

    ngOnInit(): void {
        this.rsc = this.appConfig.rsc.pages.authentication;
        this.cguService.getCguPublic().subscribe(response => {
            this.useTerms = response
        })
        this.setCguMainTitle();
    }

    setCguMainTitle(): void {
        this.mainTitle = this.rsc.useTerms.cgu.mainTitle;
    }

}
