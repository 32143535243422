import { HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from './app-config.service';
import { Injectable } from '@angular/core';

/**
 * Application Error Service
 *
 * @export
 * @class Logger
 */
@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    /**
     * Build an instance of ErrorService.
     *
     * @param {AppConfig} appConfig
     * @memberof ErrorService
     */
    constructor(
        private appConfig: AppConfig
    ) {
    }

    getErrorMessage(resp: Error | HttpErrorResponse): string {
        let errorMessage: string = this.appConfig.rsc.error.unknown;
        if (resp instanceof HttpErrorResponse) {
            if (resp.error && resp.error.message) {
                errorMessage = resp.error.message;
            } else if (resp.error && typeof resp.error === 'string') {
                errorMessage = resp.error;
            } else if (resp.status && resp.statusText) {
                errorMessage = this.appConfig.rsc.error.unknown + ` : ${resp.status} ${resp.statusText}`;
            }
        } else {
            errorMessage = resp.message || this.appConfig.rsc.error.unknown;
        }
        return errorMessage;
    }
}

