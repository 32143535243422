import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

// RxJS
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Services
import { AuthService } from '../services/auth.service';

/**
 * Application auth token Interceptor
 *
 * @export
 * @class TokenInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    /**
     * Build an instance of TokenInterceptor.
     * @param {Injector} injector
     * @memberof TokenInterceptor
     */
    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = <AuthService>this.injector.get(AuthService);
        if (auth.isAuthorizationHeaderNeeded(req)) {
            return from(auth.getTokenAsync()).pipe(
                switchMap(authenticationResult => {
                    const token = authenticationResult.accessToken || authenticationResult.idToken;
                    req = auth.addAuthorizationHeader(req, token);
                    return next.handle(req);
                }));
        } else {
            req = auth.requestOnPublicAPIM(req) ? auth.addDefaultHeader(req) : req;
            return next.handle(req);
        }
    }
}
