<mat-card appearance="outlined" [ngStyle]="{ 'margin-top':centerIcon ? '1rem' : '0' }">
    <!-- Icone en haut de la carte + titre en dessous -->
    @if (centerIcon) {
    <div xLayout="column" fxLayoutAlign="center center">
        <div class="centered-icon" [ngClass]="getIconColor()">
            <app-icon [class]="getIconClass('centered-icon-card')" [iconType]="iconType" [icon]="icon"></app-icon>
        </div>

        <div class="card-icon-title centered-text">{{ title }}</div>
    </div>
    }

    <!-- Icon et titre sur une même ligne -->
    @if (!centerIcon) {
    <div fxLayout="row" fxLayoutAlign="space-between" class="card-icon-header">
        <div class="card-icon-title">{{ title }}</div>
        <div class="icon-background row-icon" [ngClass]="getIconColor()">
            <app-icon [class]="getIconClass('row-icon-card')" [iconType]="iconType" [icon]="icon"></app-icon>
        </div>
    </div>
    }


    <mat-card-content>
        <p class="font-small" [ngClass]="{ 'centered-text': centerText }">{{ message }}</p>
        <ng-content></ng-content>
    </mat-card-content>
</mat-card>