import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';

// Prevent re-import of the core module
import { throwIfAlreadyLoaded } from './module-import-guard';

// Interceptors
import { TokenInterceptor } from './interceptors/token.interceptor';
import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { DateHttpInterceptor } from './interceptors/date-http.interceptor';

// Handlers
import { GlobalErrorHandler } from './handlers/global-error.handler';

// Calling load to get configuration + translation
import { AppConfig } from './services/app-config.service';
import { TranslationConfigModule } from './translation.config.module';
import { TranslateService } from '@ngx-translate/core';
import { AccessibilityService } from './services/accessibility.service';

// MSAL
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService,
        MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration } from '@azure/msal-angular';
import { msalConfig, apiConfig } from './b2c-config';

export function initResources(config: AppConfig, translate: TranslateService) {
    return () => config.load(translate);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...apiConfig.scopes],
        },
    };
}

@NgModule({
    imports: [
        HttpClientModule,
        TranslationConfigModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        ToastContainerModule,
        MsalModule
    ],
    exports: [
        HttpClientModule,
        TranslationConfigModule,
        ToastrModule
    ],
    providers: [
        AccessibilityService,
        AppConfig, {
            provide: APP_INITIALIZER,
            useFactory: initResources,
            deps: [AppConfig, TranslateService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
